import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { SubscriptionState } from "@api"
import { goto } from "@common/routing/router"

@customElement('membership-table-sortable')
export class MembershipTableSortable extends LitElement {
    _memberships: SubscriptionState[]
    
    _sortFunc = new SortFuncs()
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        ${unsafeCSS(materialIconsStyle)}
    `

    constructor() {
        super()
    }

    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._memberships, column)
        this.sortByFn(sortFn)
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._memberships)
        let sorted = this._memberships.sort(fn)
        this._memberships = sorted
        this.requestUpdate("memberships", oldVal)
    }
    
    set memberships(val: SubscriptionState[]) {
        let oldVal = this._memberships
        this._memberships = val
        this.requestUpdate("memberships", oldVal)
    }

    get memberships(): SubscriptionState[] {
        return this._memberships
    }

    render() {
        return html`
        <table>
            <thead>
                <th></th>
                <th class="sortable" @click="${() => this.sort('isActive')}">Aktiv</th>
                <th>Epost</th>
                <th>Sluttdato</th>
            </thead>
            <tbody>
            
                ${repeat(this.memberships, (m) => m.user.id, (m, index) => html`
                    <tr>
                        <td>
                            <a href="/users/${m.user.id}/edit" @click=${goto}>
                                <span class="material-icons" aria-hidden="true" title="gå til bruker">east</span>
                            </a>
                        </td>
                        <td>${m.isActive ? "Ja" : "Nei"}</td>
                        <td><small>${m.user.email}</small></td>
                        <td>${m.endDate ? html`<date-viewer .date=${m.endDate}></date-viewer>` : html``}</td>
                    </tr>
                `)}
            
            </tbody>
        </table>
        `
    }
}