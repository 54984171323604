<script lang="ts">
    export let department: DepartmentViewModel
    import geocoder from "@common/services/googleGeocoder"
    import { currentLocation } from "@common/store/state"
    import lang from "@common/services/lang"
    import type { DepartmentViewModel } from "@api"
    import EditRichText from "@common/components/RichText/EditRichText.svelte"

    let geocode = async () => {
        if (department.mapLookupName) {
            try {
                const res = await geocoder.geocodeAddress(department.mapLookupName)
                department.location = res.location
                department.googlePlaceId = res.placeId
                broadcastCurrent(department.location.lat, department.location.lng, department.mapLookupName)
            } catch (err) {
                errorHandler(err)
            }
        }
    }
    let broadcastCurrent = (lat: number, lng: number, title: string) => {
        currentLocation.set({ lat, lng, title })
    }
    let errorHandler = (err: Error) => {
        console.error(err)
    }
</script>
<style>
    input {
        margin-bottom: 0.3rem;
    }
    input.location {
        width: 14rem;
    }
    .header-values {
        display: flex;
        flex-direction: column;
    }
    .special-values {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .special-values mwc-textfield {
        flex: 1 1 auto;
    }
</style>
<div class="header-values">
    <mwc-textfield label="Navn" value={department.name} on:change={e => department.name = e.target.value}></mwc-textfield>
    <div class="special-values">
        <mwc-textfield label="kart lookup" value={department.mapLookupName} on:change={e => department.mapLookupName = e.target.value}></mwc-textfield>
        <mwc-textfield label="url nøkkel lowercase ingen space" value={department.routeKey} on:change={e => department.routeKey = e.target.value}></mwc-textfield>    
    </div>
</div>

<p>
    <label for="location">{lang('location')}</label><br>
    <input type="text" id="location" bind:value={department.location.lat} class="location" placeholder="Latitude">
    <input type="text" bind:value={department.location.lng} class="location" placeholder="Longitude">
    <EditRichText model={department.description} />
</p>

<button on:click={geocode}>Geocode</button>