import { SvelteSubject, clonedObservable } from "@common/store/reactive"

import rest from "@common/services/restService"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"

import type { PartnerViewModel, PartnerPostModel } from "@api"
const url = "partner"

const partnersSubject = new SvelteSubject<PartnerViewModel[]>([])
export const partners = clonedObservable(partnersSubject)

const fetchAll = async () => {
    const deps = await rest.get<PartnerViewModel[]>(url)
    partnersSubject.next(deps)
}
fetchAll()

export const createPartner = async (newPart: PartnerPostModel) => {
    const part = await rest.post<PartnerViewModel>(url, newPart)
    toast.success(`${lang("partner")} ${lang("created")}`)
    const partners = partnersSubject.get()
    partnersSubject.next([...partners, part])
    return part
}

export const getPartner = (id: string): PartnerViewModel => {
    const partners = partnersSubject.get()
    let part = partners.find(d => d.id === id)
    return part
}

export const getPartnerAsync = async (id: string) => {
    let part = getPartner(id)
    if (!part)
        part = await rest.get<PartnerViewModel>(`${url}/${id}`)
    
    if (!part.description)
        part.description = {}

    if (!part.location)
        part.location = { lat: 0, lng: 0}
    return part
}

export const updatePartner = async (part: PartnerPostModel) => {
    await rest.put(url, part)
    toast.success(`${lang("partner")} ${lang("saved")}`)
    
    const partners = partnersSubject.get()
    const index = partners.findIndex(d => d.id === part.id)
    if (index > -1) {
        partners.splice(index, 1, part)
        partnersSubject.next(partners)
    }
}
