<script lang="ts">
    interface NodeWithParent extends HTMLDivElement {
        parentNode: HTMLElement
    }

    interface ClickMouseTarget extends MouseEvent {
        target: HTMLElement
    }

    let dialogEl: NodeWithParent
    let open = false
    
    let closeDialog = (e: ClickMouseTarget) => {
        if (dialogEl && e.target && !dialogEl.contains(e.target)) {
            open = false;
            document.removeEventListener("click", closeDialog);
        }
    };

    let toggleDialog = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        open = !open;
        if (open)
            document.addEventListener("click", closeDialog);
        else
            document.removeEventListener("click", closeDialog);
    };
    
    $: style = open
        ? `left: ${dialogEl.parentNode.offsetLeft - dialogEl.offsetWidth + dialogEl.parentNode.offsetWidth}px;
              top: ${dialogEl.parentNode.offsetTop + dialogEl.parentNode.offsetHeight}px`
        : "";
    
</script>

<style>
    div {
        display: inline-block;
        user-select: none;
    }
    .popdown {
        visibility: hidden;
        z-index: -2;
        position: fixed;
        background-color: var(--mdc-theme-background);
        left: 2rem;
        color: black;
        text-align: center;
        border: none;
        padding: 1rem;
        border-radius: 6px;
        box-shadow: 0 0 40px var(--mdc-theme-text-hint-on-background), 0 0 10px var(--mdc-theme-text-primary-on-background);
        max-width: 90vw;
    }
    .popdown.open {
        z-index: 20;
        visibility: visible;
    }
</style>

<div id="popdown-wrapper">
    <div on:click="{toggleDialog}">
        <slot name="btnContent">Open dlg</slot>
    </div>
    <div class="popdown" id="popdown" class:open {style} bind:this="{dialogEl}" on:click="{toggleDialog}">
        <slot name="dlgContent">dialog content</slot>
    </div>
</div>
