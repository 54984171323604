<script lang="ts">
    import { userIsLoggedIn, logOut, userAuthInfo } from "@common/store/userProfile"
    import Link from "@common/components/Nav/Link.svelte"
    import auth from "@common/services/authentication"

    const logOff = () => {
        auth.logout()
        logOut()
    }
</script>
<style>
    nav {
        color: var(--mdc-theme-primary);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
    }
    .centre {
        text-align: center;
    }
    .material-icons.login {
        font-size: 3rem;
    }
    .material-icons.logout {
        font-size: 2rem;
        cursor: pointer;
    }
    .main-menu-items {
        flex: 1;
        padding: 0.5rem;
    }
</style>
<nav>
    {#if $userIsLoggedIn}
        <div class="centre" title="Konto">
            <Link route="/account">
                <span class="material-icons login">account_circle</span>
            </Link>
        </div>
        <div class="centre" title="Logge ut">
            <span class="material-icons logout" on:click={logOff}>
                logout
            </span>
        </div>


    {/if}

    <div class="main-menu-items">
        {#if $userIsLoggedIn}
            <Link route="/account" langCode="account" />
            {#if $userAuthInfo.isSeller}
                <div>
                    <Link route="/diagnoses">
                        <h3>Mine diagnoser</h3>
                    </Link>
                </div>
            {/if}
            {#if $userAuthInfo.isAdmin}
                <div>
                    <Link route="/diagnoses/all">
                        <h3>Alle diagnoser</h3>
                    </Link>
                    <Link route="/users" langCode="admin-users" /><br>
                    <Link route="/memberships" langCode="Medlemskap" /><br>
                </div>
            {/if}
        {/if}
        <Link route="/help" langCode="Hjelp"/>
    </div>
</nav>