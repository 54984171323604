<script lang="ts">
    import { onMount } from "svelte"
    import type { DiagnoseViewModel } from "@api"
    export let param = ""
    export let action = ""

    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    let diagnose: DiagnoseViewModel
    import { getDiagnoseDetails } from "@common/store/diagnoseStore"
    onMount(async () => {
        diagnose = await getDiagnoseDetails(param)
    })

    import View from "./DiagnoseView.svelte"
    import Edit from "./DiagnoseEdit.svelte"
    import Images from "@common/components/Files/ImagesView.svelte"
    
</script>

{#if diagnose}
    {#if action == "edit"}
        <Edit diagnose={diagnose} />
    {:else if action == "images"}
        <Images diagnose={diagnose} />
    {:else}
        <View diagnose={diagnose} />
        <ActionLink action="edit" langCode="edit" icon="edit" />
    {/if}
{:else}
    <p>Laster...</p>
{/if}