<script lang="ts">
    import type { DiagnoseViewModel, ImageFileGroupViewModel, ImageFilePostModel, StorageFilePostModel } from "@api"
    import { StorageFolder, ImageSizeType } from "@api"
    import lang from "@common/services/lang"
    import PopDown from "@common/components/PopDown.svelte"
    import DeleteDialog from "./DeleteDialog.svelte"
    import ImageUploader from "@common/components/Files/ImageUploader.svelte"
    import FileUploader from "@common/components/Files/FileUploader.svelte"
    import FileLink from "@common/components/Files/FileLink.svelte"
    import restService from "@common/services/restService"
    import toast from "@common/services/toastService"
    import { gotoPath } from "@common/routing/router"
    import {DocumentState} from "@api"
    export let diagnose: DiagnoseViewModel

    let uploadImage = async (model: ImageFilePostModel) => {
        if (!model)
            return
        
        try {
            const url = `diagnose/${diagnose.id}/addimage`
            let updated: DiagnoseViewModel = await restService.post(url, model)
            diagnose.pictures = updated.pictures
            toast.success(`${lang("image")} ${lang("created")}`)
        } catch (ex)  {
            console.error(ex)
        }
    }

    let uploadFile = async (model: StorageFilePostModel) => {
        if (!model)
            return
        
        try {
            const url = `diagnose/${diagnose.id}/addfile`
            let updated: DiagnoseViewModel = await restService.post(url, model)
            diagnose.attachments = updated.attachments
            toast.success(`${lang("file")} ${lang("created")}`)
        } catch (ex)  {
            console.error(ex)
        }
    }

    const deleteImage = async (id) => {
        try {
            const url = `diagnose/${diagnose.id}/removeimage/${id}`
            let vm: DiagnoseViewModel= await restService.remove(url)
            toast.success(`${lang("image")} ${lang("removed")}`)
            diagnose.pictures = vm.pictures
            deleteDialogState = false
        }
        catch (er) {
            console.error(er)
        }
    }
    
    let deleteDialogState = false
    let selectedAsset: ImageFileGroupViewModel = {id:"", name:"", notDeletable: true, isCropped: false}
    let openDeleteDialog = (image: ImageFileGroupViewModel) => {
        selectedAsset = image
        deleteDialogState = true
        console.log(image)
    }
    
</script>
<style>
    .list {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;
    }
    .new {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    }
</style>
<div class="new">
    {#if diagnose.state != DocumentState.Closed}
        <ImageUploader 
            folder={StorageFolder.Diagnoses} 
            subfolder="{diagnose.id}"
            onClose={(vm) => uploadImage(vm)} 
            allowNotCrop={true} 
            inlineMode={true} />

        <FileUploader
            folder={StorageFolder.Diagnoses} 
            subfolder="{diagnose.id}"
            onClose={(vm) => uploadFile(vm)} 
        />
    {/if}
</div>
<p>Filer:</p>
<div class="attachments">
    {#if diagnose.attachments}
        {#each diagnose.attachments as attachment}
            <FileLink file={attachment} />
        {/each}
    {/if}
</div>
<p>Bilder:</p>
<div class="list">
    {#if diagnose.pictures }
        {#each diagnose.pictures as image, i}
        <div class="image-wrapper">
            {#if diagnose.state != DocumentState.Closed}
                <div class="menu-row">
                    <PopDown>
                        <div slot="btnContent">
                            <span class="menu">...</span>
                        </div>
                        <div slot="dlgContent" class="flex column menu-content">
                            <div>
                                {image.name}
                            </div>
                            <div class="action">
                                <i class="material-icons mdc-button__icon" aria-hidden="true" on:click={() => openDeleteDialog(image)}>delete_forever</i>
                            </div>
                        </div>
                    </PopDown>
                </div>
            {/if}
            <image-viewer class="thumbnail" 
                image={image} 
                wantedsize={ImageSizeType.Thumbnail} 
                alt="main" on:click={() => gotoPath(`/diagnoses/${diagnose.id}/images?num=${i}`)}></image-viewer>
        </div>
        {/each}
    {:else}
        <p>ingen bilder</p>
    {/if}
    <div class="uploaders">
        
        <!-- <div class="button">
            <label for="videoInput"><fa-icon icon={faVideo}></fa-icon></label>
            <input id="videoInput" type="file" accept="video/*" capture="environment" />
        </div>
         -->
    </div>
</div>
<DeleteDialog openState={deleteDialogState} id={selectedAsset.id} name={selectedAsset.name} remove={deleteImage} />