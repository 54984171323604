/*
  do some initials like check if is logged in
*/

import firebase from "firebase/app"
import userauth from "@common/services/userauth"
import urlParser from "@common/funcs/urlParser"
import auth from "@common/services/authentication"
import { langInit } from "@common/services/lang"
import { appInitialized } from "@common/store"
import { setInitialHref } from "@common/routing/router"
// import "./components/Stars/StarsSvgDef.js";
import "@common/components/Spinner"
import "@common/components/ratingDynStars"
import "@common/components/CheckMarkGreen"
import "@common/components/Formatters/DateViewer"
import "@common/components/Formatters/DateTimeViewer"
import "@common/components/Formatters/DistanceViewer"
import "@common/components/Files/ImageViewer"
import "@common/components/FontAwesomeIcon"
import "@common/components/DirectionSign"
import "@common/components/Blog/BlogPreview"
import "@common/components/Files/ImageListSelector"
import "@common/components/Files/AssetImageViewer"
import "@common/components/Files/AssetListSelector"
import "@common/components/Files/ImageListViewer"
import "@app/views/blogposts/BlogPostList"
import "@common/components/Formatters/BooleanViewer"
import "@common/components/Blog/HtmlPreview"
import "@common/components/Orders/OrderTableSortable"
import "@common/components/Users/UserViewer"
import "@common/components/Users/UserIdViewer"
import "@common/components/BdButton"
import "@common/components/Car/CarData"

import "@common/components/Users/UsersSelector"
import "@common/components/Users/UsersListSelector"

import "@common/components/RichText/ProseEditor"
import "@common/components/Users/AuthProvider"

import "@app/components/departmentViewer"
import "@app/components/departmentsIdViewer"
import "@common/components/StateViewer"
import "@common/components/StateIcon"

import "@material/mwc-icon"
import "@material/mwc-textarea"
import "@material/mwc-textfield"
import "@material/mwc-tab-bar"
import "@material/mwc-tab"
import "@material/mwc-select"
import "@material/mwc-list"
import "@material/mwc-list/mwc-list-item"
import "@material/mwc-checkbox"
import "@material/mwc-formfield"
import "@material/mwc-radio"
import "@material/mwc-fab"

let alreadyCheckedFirebase = false

const initialize = async () => {
    firebase.initializeApp({
        "apiKey": "AIzaSyBEWEHpDpfWdixmV4FN1TKUiAQd5RPVnnA",
        "appId": "1:368633701466:web:eabb4937864cd3a3872463",
        "authDomain": "bildoktoren-no.firebaseapp.com",
        "databaseURL": "https://bildoktoren-no.firebaseio.com",
        "measurementId": "G-VELVGLHK1K",
        "messagingSenderId": "368633701466",
        "projectId": "bildoktoren-no",
        "storageBucket": "bildoktoren-no.appspot.com"
    })

    await langInit()
    const action = urlParser.getAction()
    const isEmailReset = (action && action.mode === "verifyEmail")
    firebase.auth().onAuthStateChanged(async (user) => {
        appInitialized.set(true)
        if (user) {
            const idToken = await auth.getIdToken()
            document.cookie = "bearer" + "=" + idToken
            const up = await userauth.onuserIsLoggedIn(user)
            // if (!user.emailVerified && !isEmailReset) {
            //     user.sendEmailVerification().then(() => {
            //         console.log("confirmation mail sent")
            //     }).catch((err) => {
            //         console.error(err)
            //     });
            // }
        } else {
            userauth.setLoggedOutUserProfile()
        }
        if (!alreadyCheckedFirebase) {
            setInitialHref()
            alreadyCheckedFirebase = true
        }
    });
    if (action && action.mode) {
        console.log(`call action ${action.mode}`)
    }
}

export default { initialize }

import { materialIconsStyle } from "@common/style/stylesheets"
const styleEl = document.createElement("style")
const codeEl = document.createTextNode(materialIconsStyle)
styleEl.appendChild(codeEl)
document.head.appendChild(styleEl)
//document.adoptedStyleSheets = [materialIconsStyle]

