<script lang="ts">
    import { onMount } from "svelte"
    import type { DepartmentViewModel } from "@api"
    import { getDepartmentAsync } from "@app/store/departmentStore"
    import Edit from "./Edit.svelte"
    import Products from "./Products.svelte"
    import ProductLinks from "./ProductLinks.svelte"
    import lang from "@common/services/lang"
    export let param: string
    //export let action: string

    let activeIndex = 0

    let tabbarEl

    let dep: DepartmentViewModel

    onMount(async () => {
        dep = await getDepartmentAsync(param)
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            activeIndex = e.detail.index
        })
    })
    // let pages = [{
    //     id: "depInfo",
    //     label: "dep-info",
    //     component: Edit
    // },{
    //     id: "products",
    //     label: "products",
    //     component: Products
    // },{
    //     id: "links",
    //     label: "links",
    //     Comment: ProductLinks
    // }]
    
    // let currentIndex = 0
    // let currentPage = pages[currentIndex]
    // $: if (action) {
    //     currentPage = pages.find(p => p.id == action)
    //     currentIndex = pages.indexOf(currentPage)
    //     if (!currentPage) {
    //         currentIndex = 0
    //         currentPage = pages[currentIndex]
    //     }
    // }
</script>

<mwc-tab-bar bind:this={tabbarEl}>
    <mwc-tab label="{lang('dep-info')}" icon="info" stacked></mwc-tab>
    <mwc-tab label="{lang('products')}" icon="inventory_2" stacked></mwc-tab>
    <mwc-tab label="{lang('links')}" icon="link" stacked></mwc-tab>
</mwc-tab-bar>

{#if activeIndex == 1}
    <Products param={dep} />
{:else if activeIndex == 2}
    <ProductLinks param={dep} />
{:else}
    <Edit param={dep} />
{/if}

