<script lang="ts">

    import Link from "@common/components/Nav/Link.svelte"
    import { userAuthInfo } from "@common/store/userProfile"
    import { goto } from "@common/routing/router"
    import DiagnosesLastFive from "./diagnoses/LastFive.svelte"

</script>
<style>
    .material-icons {
        font-size: 4rem;
        color: var(--mdc-theme-primary);
    }
</style>


<article class="default">
    <header>
        
    </header>

    <div>
        {#if $userAuthInfo.loggedIn}
            {#if $userAuthInfo.isAdmin}
                <div>
                    <Link route="/blog" langCode="admin-blog" /><br>
                    <Link route="/users" langCode="admin-users" /><br>
                    <Link route="/memberships" langCode="Medlemskap" /><br>
                    <Link route="/products" langCode="admin-products" /><br>

                    <Link route="/assets" langCode="admin-assets" /><br>
                    <Link route="/content" langCode="admin-content" /><br>

                    <Link route="/departments" langCode="admin-departments" /><br>
                    <Link route="/partners" langCode="admin-partners" /><br>
                    <Link route="/orders" langCode="orders" /><br>
                </div>
            {/if}
            {#if $userAuthInfo.isSeller || $userAuthInfo.isAdmin}
                <section class="split-in-two">
                    <div class="split subcontent">
                        <div>
                            <Link route="/diagnoses">
                                <h2>Mine diagnoser</h2>
                            </Link>
                        </div>
                        {#if $userAuthInfo.isAdmin}
                            <div>
                                <Link route="/diagnoses/all">
                                    <h2>Alle diagnoser</h2>
                                </Link>
                                
                            </div>
                        {/if}
                        <p>
                            <Link route="/diagnoses/create">
                                <span class="material-icons" aria-hidden="true" title="Ny diagnose">build</span>
                                <span>Ny diagnose</span>
                            </Link>
                        </p>
                    
                        
                    </div>
                    <div class="split">
                        <h3>Siste 5 diagnoser</h3>
                        <DiagnosesLastFive />
                    </div>
                </section>
                
            {/if}

        {:else}
            <a href="/signin" on:click={goto}>
                <h2>Logg inn</h2>
            </a>
        {/if}
    </div>
</article>