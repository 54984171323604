<script lang="ts">
    export let param = ""
    export let action = ""
    import lang from "@common/services/lang"
    import Link from "@common/components/Nav/Link.svelte"
    import User from "./User.svelte"
    import Create from "./Create.svelte"
    import "./UserTableSortable"
    import "./RoleViewer"
    import "./ApplicationViewer"
    import { goto } from "@common/routing/router"
    import {allUsersAdmin} from "@common/store/userAdminStore"
</script>
<style>
    
</style>
<article class="default">
    <header>
        <Link route="/users">
            <h3>{lang('users')}</h3>
        </Link>
    </header>
    
    <section class="split-in-two left">
        <div class="split">
            <div class="new">
                <a href="/users/create" on:click={goto}>
                    <span class="material-icons">create_new_folder</span>
                    <span>Opprett ny</span>
                </a>
                
            </div>
            <div class="split subcontent">
                <user-table-sortable users={$allUsersAdmin}></user-table-sortable>
            </div>
        
        </div>
        <div class="split">
            {#if param == "create"}
                <Create />
            {:else if action == "edit"}
                <User param={param} />
            {/if}
            
        </div>
    </section>

</article>


