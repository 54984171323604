<script lang="ts">
    import { onMount } from "svelte"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import { getAllClone } from "@common/store/productPublicStore"
    import { updateDepartment } from "@app/store/departmentStore"
    import type { DepartmentViewModel } from "@api"
    let allProducts = []
    export let param: DepartmentViewModel

    onMount( async () => {
        allProducts = await getAllClone()
        setChecked()
    })

    $: if (param)
        setChecked()

    let setChecked = () => {
        if (param && param.products && Array.isArray(param.products) && param.products.length > 0) {
            allProducts = allProducts.map(p => {
                p.checked = param.products.some(up => up.id === p.id)
                return p
            })
        }
    }
    let update = async () => {
        const checkedProducts = allProducts.filter(p => p.checked)
        param.products = checkedProducts
        await updateDepartment(param)
        toast.success(`${lang("products")} ${lang("saved")}`)
    }
</script>
<style>
    .products {
        margin-bottom: 1rem;
    }
</style>
<h4>{lang('products-offering')}</h4>
{#each allProducts as product}
    <div class="products">
        <input type="checkbox" bind:checked={product.checked}>
        <span>{product.name}</span>
    </div>
{/each}
<button on:click|preventDefault={update}>{lang('save')}</button>