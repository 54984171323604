<script lang="ts">
    import type {BlogPostViewModel } from "@api"
    import {ImageSizeType } from "@api"
    import lang from "@common/services/lang"
    import EditRichText from "@common/components/RichText/EditRichText.svelte"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"
    export let post: BlogPostViewModel
    let emptyGuid = "00000000-0000-0000-0000-000000000000"
    let selectDialogOpen = false
    let onImageSelected = (e) => {
        let id = e.detail.id
        post.assetId = id
        selectDialogOpen = false
    }
    let removeimg = () => { post.assetId = emptyGuid }
</script>

<style>
    .image-select {
        cursor: pointer;
        display: inline-block;
    }
    .no-image {
        display: inline-block;
        margin: 2rem 0;
    }
    .remove-img {
        cursor: pointer;
    }
    mwc-textfield {
        width: 70%;
    }
    mwc-textfield.small-num {
        width: 7rem;
    }
    @media only screen and (max-width: 1280px) {
        mwc-textfield {
            width: 100%;
        }
    }
</style>

<mwc-textfield label="{lang('headline')}" value={post.headline} on:change={e => post.headline = e.target.value}></mwc-textfield><br>
<mwc-textfield label="Ekstern link" value={post.externalLink} on:change={e => post.externalLink = e.target.value}></mwc-textfield><br>
<mwc-textfield label="Permalink URL for SEO (unik, ingen store bokstaver, spesialtegn, æøå eller mellomrom, bør ikke endres etter publisering)" value={post.routeKey} on:change={e => post.routeKey = e.target.value}></mwc-textfield>
<br>
<div class="image-select" on:click={() => selectDialogOpen = true}>
    {#if post.assetId && post.assetId != emptyGuid}
        <asset-image-viewer id={post.assetId} wantedsize={ImageSizeType.Thumbnail}></asset-image-viewer>
    {:else}
        <div class="no-image">Ingen bilde satt, trykk for å velge</div>
    {/if}
</div>
{#if post.assetId && post.assetId != emptyGuid}
    <div class="remove-img" title="fjerne bilde fra blogpost">
        <i class="material-icons mdc-button__icon" aria-hidden="true" on:click={() => removeimg()}>delete_forever</i>
    </div>
{/if}
<br>

<EditRichText model={post.body} />

<mwc-formfield label="Kladd">
    <mwc-checkbox checked={post.isDraft} 
        on:change={(e) => post.isDraft = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<mwc-formfield label="På fremsiden">
    <mwc-checkbox checked={post.onFrontPage} 
        on:change={(e) => post.onFrontPage = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>

<mwc-textfield class="small-num" label="Rekkefølge" value="{post.order}"
    on:change={(e) => post.order = e.target.value}>
</mwc-textfield>

<ModalDialog open="{selectDialogOpen}">
    <div slot="btnContent"></div>
    <div class="picframe" slot="dlgContent">
        <asset-list-selector on:imageselected={onImageSelected}></asset-list-selector>
    </div>
</ModalDialog>