import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { UserListModel, UserViewModel } from "@api"
import lang from "@common/services/lang"
import {addUser} from "@common/store/usersStore"

@customElement('user-selector')
export class UserSelector extends LitElement {
    private newUserMode = false
    private filter = ""
    private filteredUsers: UserListModel[] = []
    static styles = css`
        :host {
            display: block;
            min-width: 50vw;
        }
        .list {
            display: flex;
            flex-direction: column;
        }
        .controls {
            display: flex;
            flex-direction: row;
        }
        .controls div {
            flex: 1;
        }
        .user-wrapper {
            cursor: pointer;
            padding: 0.2rem;
        }
        .user-wrapper:hover {
            box-shadow: var(--bd-box-shadow-hover);
        }
        .right {
            display: flex;
            flex-direction: row-reverse;
        }
        input {
            margin-bottom: 0.3rem;
            width: 200px;
        }
        mwc-textfield {
            width: 95%;
        }
    `
    @property({attribute: false})
    users: UserListModel[]

    emitSelectEvent(id: string) {
        var evt = new CustomEvent('userselected', {
            bubbles: true,
            composed: true,
            detail: { id }
        })
        this.dispatchEvent(evt)
    }

    dofilter(e: InputEvent) {
        const inputEl = e.target as HTMLInputElement
        this.filter = inputEl.value
        console.log(this.filter)
        if (this.users.length > 0)
            this.performFilter()

    }

    performFilter() {
        if (!this.filter) {
            this.filteredUsers = []    
        } else {
            this.filteredUsers = this.users.filter(u => u.email.includes(this.filter) || u.name.includes(this.filter))
        }
        this.requestUpdate()
    }
    toggle() {
        this.newUserMode = !this.newUserMode
        this.requestUpdate()
    }
    async saveNewUser() {
        const newuser: UserViewModel = {
            name: this.getFieldValue("name"),
            email: this.getFieldValue("email"),
            address: this.getFieldValue("address"),
            postalCode: this.getFieldValue("postal-code"),
            place: this.getFieldValue("place")
        }
        const user = await addUser(newuser)
        this.emitSelectEvent(user.id)
        this.toggle()
    }
    getFieldValue(id: string) {
        return (this.shadowRoot.querySelector(`#${id}`) as HTMLInputElement).value
    }
    render() {
        if (!this.users)
            return html`<p>Ingen kunder</p>`
        
        let users = this.users
        if (this.filteredUsers.length > 0)
            users = this.filteredUsers
        
        if (this.newUserMode) {
            return html`
                <mwc-textfield label="${lang('name')}" id="name"></mwc-textfield><br>
                <mwc-textfield label="${lang('email')}" id="email"></mwc-textfield><br>
                <mwc-textfield label="${lang('address')}" id="address"></mwc-textfield><br>
                <mwc-textfield label="${lang('postal-code')}" id="postal-code"></mwc-textfield><br>
                <mwc-textfield label="${lang('place')}" id="place"></mwc-textfield><br>
                <br>
                <bd-button @click=${this.saveNewUser}>Lagre ny kunde</bd-button>
            `
        }
        return html`
            <h4>Velg kunde</h4>
            
            <div class="controls">
                <div>
                    <input id="userfilter" placeholder="filter" @input=${this.dofilter} />
                </div>
                <div class="right">
                    <bd-button @click=${this.toggle}>Ny kunde</bd-button>
                </div>
            </div>
            
            <div class="list">
                ${users.map(user => {
                    return html`
                        <div class="user-wrapper" @click=${() => this.emitSelectEvent(user.id)}>
                            <user-viewer .user=${user}></user-viewer>
                        </div>
                    `
                })}
            </div>
        `
    }
}