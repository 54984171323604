import { SvelteSubject } from "@common/store/reactive"
import { userAuthInfo } from "@common/store/userProfile"

import rest from "@common/services/restService"

import type { UserViewModel } from "@api"
const url = "user"

const allUsersSub = new SvelteSubject<UserViewModel[]>([])
export const allUsersAdmin = allUsersSub.asObservable()

export const fetchAllUsersAdmin = async () => {
    const all = await rest.get<UserViewModel[]>(url)
    allUsersSub.next(all)
}

export const getUserAdmin = (userId: string) => {
    const all = allUsersSub.getValue()
    const user = all.find(u => u.id == userId)
    return user
}

userAuthInfo.subscribe(ua => {
    if (ua.isAdmin)
        fetchAllUsersAdmin()
    else
        allUsersSub.next([])
})
