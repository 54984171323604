import { SvelteSubject } from "@common/store/reactive"
import rest from "@common/services/restService"
import type { OrderListModel, OrderViewModel } from "@api"
import { userAuthInfo } from "@common/store/userProfile"

const baseurl = "order"

const ordersStoreSubject = new SvelteSubject<OrderListModel[]>([])
export const orders = ordersStoreSubject.asObservable()

const fetchOrders = async () => {
    const orders = await rest.get<OrderListModel[]>(baseurl)
    ordersStoreSubject.next(orders)
}

userAuthInfo.subscribe(user => {
    if (user.loggedIn)
        fetchOrders()
})

export const fetchOrder = async (orderId: string) => {
    let url = `${baseurl}/${orderId}`
    const order = await rest.get<OrderViewModel>(url)
    return order
}