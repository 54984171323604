<script lang="ts">
    import { onDestroy, onMount } from "svelte"
    import DiagnoseForm from "./DiagnoseForm.svelte"
    import DiagnoseDescription from "./DiagnoseDescription.svelte"
    import Attachments from "./Attachments.svelte"
    import config from "@common/config"
    import { writeToClipBoard } from "@common/services/clipboardService"

    import type { DiagnoseViewModel } from "@api"
    import {DocumentState} from "@api"
    import lang from "@common/services/lang"
    export let diagnose: DiagnoseViewModel
    import { updateDiagnose } from "@common/store/diagnoseStore"
    import { curRoute, setSearchParam } from "@common/routing/router"
    import type { Subscription } from "rxjs"

    const save = async () => {
        diagnose = await updateDiagnose(diagnose)
    }
    let sub: Subscription
    let tabbarEl
    let activeIndex = 0
    onMount(() => {
        sub = curRoute.subscribe(r => {
            if (r.search) {
                if (r.search.has("tab")) {
                    const tabString = r.search.get("tab")
                    activeIndex = parseInt(tabString)
                }
            }
        })
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            // activeIndex = e.detail.index
            setSearchParam("tab", e.detail.index)
        })
    })
    
    onDestroy(() => sub.unsubscribe())

    const selectLink = (e) => {
        const el = e.target
        el.focus()
        el.select()
        writeToClipBoard(el.value)
    }
    const onUserSelected = (e) => {
        save()
    }
</script>
<style>
    .customer-link {
        font-size: small;
        background: var(--bd-background-secondary);
        padding: 0.2rem;
        border: 1px solid var(--mdc-theme-primary);
        width: 400px;
    }
    @media only screen and (max-width: 640px) {
        .customer-link {
            font-size: small;
            width: 100%;
        }
    }
</style>
<mwc-tab-bar bind:this={tabbarEl} activeIndex="{activeIndex}">
    <mwc-tab label="{lang('diagnose')}" icon="info" stacked></mwc-tab>
    <mwc-tab label="beskrivelse" icon="description" stacked></mwc-tab>
    <mwc-tab label="{lang('attachments')}" icon="attach_file" stacked></mwc-tab>
</mwc-tab-bar>

{#if activeIndex == 2}
    <Attachments diagnose={diagnose} />
{:else if activeIndex == 1}
    <DiagnoseDescription diagnose={diagnose} />
{:else}
    <DiagnoseForm diagnose={diagnose} on:userselected={onUserSelected}/>
    <br>
    <car-data car={diagnose.carData}></car-data>
    <p>
        Link til kunde:
        <input class="customer-link" readonly
            value="{config.webUrl}/diagnoses/{diagnose.id}"
            on:click={selectLink} />
    
    </p>
    {#if diagnose.state != DocumentState.Closed}
        <button on:click={save}>{lang('save')}</button>
    {/if}
{/if}
