
import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import {usersList} from "@common/store/usersStore"
import { observe } from "@common/directives/ObservableDirective"
import type { UserListModel } from "@api"
@customElement('users-list-selector')
export class UsersListSelector extends LitElement {
    
    static styles = css`
        :host {
            display: block;
        }
    `
    
    render() {
        return html`
            ${observe(usersList, (users: UserListModel[]) => {
                return html`<user-selector .users=${users} ></user-selector>`
            })}
        `
    }
}