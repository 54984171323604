import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('boolean-viewer')
export class BooleanViewer extends LitElement {
    
    static styles = css`
        :host {
            display: inline-block;
        }
    `;

    @property({attribute: false})
    value: boolean

    @property({attribute: true})
    truelabel: string

    @property({attribute: true})
    falselabel: string
    

    render() {
        let truelabel = "Ja"
        if (this.truelabel)
            truelabel = this.truelabel
        let falselabel = "Nei"
        if (this.falselabel)
            falselabel = this.falselabel
        
        if (this.value)
            return html`${truelabel}`
        return html`${falselabel}`
    }
}