<script lang="ts">
    export let param = ""
    export let action = ""
    import { onMount } from "svelte"
    import restService from "@common/services/restService"
    import lang from "@common/services/lang"
    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import Edit from "./Edit.svelte"
    import Link from "@common/components/Nav/Link.svelte"
    import type { AreaViewModel } from "@api"
    //import OpenStreetMap from "../../../components/Maps/OpenStreetMap.svelte"
    
    let areas: AreaViewModel[] = []
    let errorMsg: string
    let listAreas = async () => {
        errorMsg = ""
        const url = "area"
        try {
            areas = await restService.get(url)
        } catch (ex) {
            console.error(ex)
        }
    }
    
    onMount(() => {
        listAreas()
    })
</script>
<style>
    .error {
        color: var(--red-dark);
    }

</style>
<article class="default">
    <header>
        <Link route="/areas">
            <h3>{lang('areas')}</h3>
        </Link>
    </header>
    <section class="split-in-two">
        <div class="split subcontent">
            <div>
                {#if param == "create"}
                    <Create />
                {:else}
                    {#if action == "edit"}
                        <Edit param={param} />
                    {:else}
                        <List areas={areas} />
                    {/if}
                {/if}
                
            </div>
        
            <div class="error">{errorMsg}</div>
        </div>
        <div class="split">
            <!-- <OpenStreetMap areas={areas} zoom={4}/> -->
        </div>
    </section>

</article>


