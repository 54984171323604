import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement, property} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { UserViewModel } from "@api"

@customElement('user-table-sortable')
export class UserTableSortable extends LitElement {
    _users: UserViewModel[]
    _nameLabel = lang("name")
    _roleLabel = lang("role")
    _authLabel = lang("authprovider")
    
    _sortFunc = new SortFuncs()
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }

    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._users, column);
        this.sortByFn(sortFn);
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._users);
        let sorted = this._users.sort(fn);
        this._users = sorted;
        this.requestUpdate("users", oldVal);
    }
    
    set users(val: UserViewModel[]) {
        let oldVal = this._users;
        this._users = val;
        this.requestUpdate("users", oldVal)
    }

    get users(): UserViewModel[] {
        return this._users;
    }

    render() {
        return html`
        <table>
            <thead>
                <th></th>
                <th class="sortable" @click="${() => this.sort('name')}">${this._nameLabel}</th>
                <th>Epost</th>
                <th class="sortable" @click="${() => this.sort('role')}">${this._roleLabel}</th>
                <th class="sortable" @click="${() => this.sort('provider')}">${this._authLabel}</th>
                <th class="sortable">Avdeling</th>
            </thead>
            <tbody>
            
                ${repeat(this.users, (u) => u.id, (u, index) => html`
                    <tr>
                        <td>
                            <a @click=${goto} href="/users/${u.id}/edit">
                                <span class="material-icons" aria-hidden="true" title="${lang('edit')}">edit</span>
                            </a>
                        </td>
                        <td>${u.name}</td>
                        <td><small>${u.email}</small></td>
                        <td>
                            <role-viewer .user=${u}></role-viewer>
                        </td>
                        <td>
                            <auth-provider .user=${u}></auth-provider>
                        </td>
                        <td>
                            <departments-id-viewer .departmentIds=${u.departmentIds}></departments-id-viewer>
                    </tr>
                `)}
            
            </tbody>
        </table>
        `;
    }
}