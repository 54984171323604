<script lang="ts">
    export let param = ""
    export let action = ""
    import lang from "@common/services/lang"
    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import Edit from "./Edit.svelte"
    import Link from "@common/components/Nav/Link.svelte"

</script>

<article class="default">
    <header>
        <Link route="/blog">
            <h3>{lang('blogposts')}</h3>
        </Link>
    </header>
    <section>
        {#if param == "create"}
            <Create />
        {:else}
            {#if action == "edit"}
                <Edit param={param} />
            {:else}
                <List />
            {/if}
        {/if}
    </section>

</article>


