<script lang="ts">
    import lang from "@common/services/lang"
    import { OrderStatus } from "@api"
    import type { OrderViewModel } from "@api"

    export let order: OrderViewModel = {
        created: new Date().toISOString(),
        noteFromBuyer: "",
        orderNumber: "",
        status: OrderStatus.Created,
        lines: []
    }
</script>

<article class="default secondary">
    <header class="header white-bg">
        <div>
            Bestillingsdato: <date-viewer date={order.created}></date-viewer><br>
            Ordrenr: {order.orderNumber}<br>
            Notat: {order.noteFromBuyer}<br>
            Status: {lang(order.status.toString())}
        </div>
        <div>
            {#if order.user}
                Bruker: {order.user.name}<br>
                Epost: {order.user.email}
            {/if}
        </div>
    </header>
    <section class="white-bg">
        <!-- {#if order.status === OrderStatus.Created}
            <button on:click={remove}>Slett</button>
        {/if} -->
        {#each order.lines as line}
            <p>
                {line.productDescription}
                {line.variantDescription}
                - {line.price} ,-
            </p>
        {/each}
    </section>
</article>