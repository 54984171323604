<script lang="ts">
    
</script>
<style>
    img {
        border: 1px solid grey;
    }

</style>
<article class="default">
    <header>
        <h1>Hjelp</h1>
    </header>

    <section>
        <h2>Innledning</h2>
        <p>Applikasjonen for Bildoktoren administrasjon fungerer både på PC, nettbrett og mobil. Forskjellen vil bare ligge i "layout", altså at på mobil vil noen elementer potensielt kreve scrolling, også sidelengs,
            dette gjelder eksempelvis tab menyen inne på en diagnose som vist på bildet her <br>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminDiagnoseMobilScroll.jpg" alt="mobil scroll">
        </p>

        <h2>Bruker</h2>
        <p>Alle må autentisere seg med en gyldig bruker for å bruke Bildoktoren Admin. Man vil få opp en lenke til innloggingssiden dersom man ikke er logget inn</p>
        <p>For å logge ut kan man klikke på "hamburger"-menyen oppe til høgre og trykke på knappen som vist på bilde<br>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/LoggUt.jpg" alt="Logge ut">
        </p>

        <h2>Fremsiden</h2>
        <p>På fremsiden får man opp en knapp for å registrere Ny diagnose, en lenke til oversikt over alle sine diagnoser, samt en kort-liste over de siste 5 registrere diagnosene</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminFremside.jpg" alt="fremside">
        </p>

        <h2>Ny diagnose</h2>
        <p>Når man trykker på "Ny diagnose" får man først opp et enkelt skjema bare bestående av Avdeling, kort beskrivelse, kunde og registreringsnummer, avdeling har bare ett valg og er pre-valgt. 
            Deretter er kort beskrivelse påkrevd, mens man må ikke nødvendigvis fylle ut kunde og registreringsnummer med en gang</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminNyDiagnose.jpg" alt="ny diagnose">
        </p>

        <h2>Redigere diagnose</h2>
        <p>Når man har opprettet en ny diagnose vil man få opp muligheten til å skrive inn utfyllende tekst, samt laste opp bilder</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminDiagnoseEdit.jpg" alt="diagnose edit">
        </p>

        <h2>Laste opp bilder</h2>
        <p>For laste opp bilder klikker man på den øverste av de to knappene, for å ta bilde direkte med kamera klikker man på kamera knappen, sistnevnte vil fungere bra på mobil</p>
        <p>Å laste opp bildet kan ta litt tid avhengig av nettforbindelse og kvalitet på bildet, det vil dukke opp som thumbnail når det er kommet inn</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminUploadImages.jpg" alt="laste opp bilder">
        </p>

        <h2>Velge kunde</h2>
        <p>På en diagnose setter man en kunde ved å trykke på "Velg kunde" knappen, eller trykke på navnet til kunden som tidligere er satt dersom man valgte feil kunde</p>
        <p>Da får man opp en dialog hvor man kan søke mellom eksisterende kunder, eller opprette ny</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminChooseUser.jpg" alt="velge bruker">
        </p>
        <p>Navn og epost er påkrevd ved opprettelse av ny kunde</p>
        <p>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/help/AdminNewCustomer.png" alt="ny kunde">
        </p>
    </section>
</article>
