<script lang="ts">
    export let param = ""
    export let action = ""

    import Link from "@common/components/Nav/Link.svelte"
    import lang from "@common/services/lang"
    import { partners } from "@app/store/partnerStore"

    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import PartnerInfo from "./PartnerInfo.svelte"
</script>
<style>


</style>
<article class="default">
    <header>
        <Link route="/partners">
            <h3>{lang('admin-partners')}</h3>
        </Link>
    </header>
    <section>
        <div>
            {#if param == "create"}
                <Create />
            {:else if !param}
                <List partners={$partners} />
            {:else}
                <PartnerInfo param={param} action={action} />
            {/if}
            
        </div>
    </section>

</article>