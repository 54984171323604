<script lang="ts">
    import restService from "@common/services/restService"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import AreaForm from "./AreaForm.svelte"
    import type { AreaViewModel } from "@api"

    let newArea: AreaViewModel = {
        id: "",
        name: "",
        mapLookupName: "",
        location: { lat: 0, lng: 0 },
        legacyLink: "",
        googlePlaceId: ""
    }

    //let errorMsg: string
    let createArea = async () => {
        //errorMsg = ""
        const url = "area"
        try {
            await restService.post(url, newArea)
            toast.success(`${lang("area")} ${lang("created")}`)
        } catch (ex)  {
            errorHandler(ex)
        }
    }
    
    let errorHandler = (ex: Error) => {
        //errorMsg = ex.message.substring(0,500);
        console.log(ex.message)
    }
</script>

<AreaForm area={newArea} />
<button on:click={createArea}>{lang('create')}</button>