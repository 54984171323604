let permissionAllowed = false

import toastService from "@common/services/toastService"

const isClipboardWriteAllowed = async () => {
    if (!navigator.clipboard)
        return false

    // @ts-ignore
    const result = await navigator.permissions.query({name: "clipboard-write"})
    // @ts-ignore
    if (result.state == "granted" || result.state == "prompt")
        permissionAllowed = true
}

isClipboardWriteAllowed()

export const writeToClipBoard = (text: string) => {
    if (!permissionAllowed)
        return
    
    navigator.clipboard.writeText(text).then(() => {
        toastService.success("kopiert til utklippstavle")
    }, (er) => {
        console.log("error copying", er)
    })
}