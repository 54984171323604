<script>
import { goto } from "@common/routing/router";


    
</script>
<style>
    nav {
		width: 1600px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
        flex-wrap: wrap;
        background: var(--mdc-theme-primary);
        color: var(--mdc-theme-surface);
        padding: 1rem;
	}
    div.box {
        display: flex;
        flex-direction: column;
    }
    a {
        color: var(--mdc-theme-surface);
    }
    @media only screen and (max-width: 1600px) {
        nav {
            width: 100%;
        }
    }
    
    @media only screen and (max-width: 640px) {
        nav {
			width: 100%;
		}
    }
    
</style>
<nav>
    <div class="box">
        <h2>BILDOKTOREN AS</h2>
    </div>
    <div class="box">
        <h3>Admin</h3>
    </div>
    <div class="box">
        
    </div>
    <div class="box">
        <a href="/help" on:click={goto}>Hjelp</a>
    </div>
</nav>