<script lang="ts">
    import { ImageSizeType } from "@api"
    import type { ImageFileGroupViewModel } from "@api"
    import { imageAssetStore } from "@common/store/assetsStore"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"
    export let onSetImage: Function
    let uploadDialogState = false

    let selectPic = (asset: ImageFileGroupViewModel) => {
        console.log(`id=${asset.id}`)
        if (onSetImage)
            onSetImage(asset)
        uploadDialogState = false
    }
</script>
<style>
    .list {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    image-viewer:hover {
        box-shadow: 0 0 40px var(--mdc-theme-text-hint-on-background), 0 0 10px var(--mdc-theme-text-primary-on-background);
        cursor: pointer;
    }
</style>
<ModalDialog open="{uploadDialogState}">
    <div slot="btnContent">
        <button class="open-upload" on:click={() => uploadDialogState = true}>
            <mwc-icon>library_add</mwc-icon>
        </button>
    </div>    
    <div class="list" slot="dlgContent">
        {#each $imageAssetStore as asset}
        <div class="image-wrapper">
            <image-viewer class="thumbnail" image={asset} wantedsize={ImageSizeType.Thumbnail} on:click={() => selectPic(asset)} alt="pic"></image-viewer>
        </div>
        {/each}
    </div>
</ModalDialog>
