<script lang="ts">
    export let param = ""
    export let action = ""

    import Info from "./OrderInfo.svelte"
    import Link from "@common/components/Nav/Link.svelte"
    import lang from "@common/services/lang"
    import { orders } from "@common/store/orderStore"
    
</script>
<style>


</style>
<article class="default">
    <header>
        <Link route="/orders">
            <h3>{lang('orders')}</h3>
        </Link>
    </header>
    <section class="split-in-two">
        <div class="split subcontent">
            <order-table-sortable orders={$orders}></order-table-sortable>
        </div>
        <div class="split">
            {#if param}
                <Info orderid={param} action={action} />
            {/if}
        </div>
    </section>

</article>