<script lang="ts">
    import type { AreaViewModel } from "@api"
    export let area: AreaViewModel
    import geocoder from "@common/services/googleGeocoder"
    import { currentLocation } from "@common/store/state"
    import lang from "@common/services/lang"

    let geocode = async () => {
        if (area.mapLookupName) {
            try {
                const res = await geocoder.geocodeAddress(area.mapLookupName)
                area.location = res.location
                area.googlePlaceId = res.placeId
                broadcastCurrent(area.location.lat, area.location.lng, area.mapLookupName)
            } catch (err) {
                errorHandler(err)
            }
        }
    }
    let broadcastCurrent = (lat: number, lng: number, title: string) => {
        currentLocation.set({ lat, lng, title })
    }
    let errorHandler = (err: Error) => {
        console.error(err)
    }
</script>
<style>
    input {
        margin-bottom: 0.3rem;
    }
    input.location {
        width: 14rem;
    }
</style>
<input type="text" bind:value={area.name} placeholder="area name" /><br>
<input type="text" bind:value={area.mapLookupName} placeholder="map lookup name" /><br>
<input type="text" bind:value={area.legacyLink} placeholder="link" />
<p>
    <label for="location">{lang('location')}</label><br>
    <input type="text" id="location" bind:value={area.location.lat} class="location" placeholder="Latitude">
    <input type="text" bind:value={area.location.lng} class="location" placeholder="Longitude">
</p>

<button on:click={geocode}>Geocode</button>