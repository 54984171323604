import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {getDepartment} from "@app/store/departmentStore"

@customElement('departments-id-viewer')
export class DepartmentsIdViewer extends LitElement {

    static styles = css`
        :host {
            display: inline-block
        }
    `
    @property({attribute: false})
    departmentIds: string[] = []

    @property({attribute: false})
    
    render() {
        if (!this.departmentIds || this.departmentIds.length == 0)
            return html`<span></span>`
        
        return this.departmentIds.map(dId => {
            const dep =  getDepartment(dId)
            return html`
                <department-viewer .department=${dep}></department-viewer>
            `
        })
    }
}