<script lang="ts">
    import type { DiagnoseViewModel } from "@api"

    export let diagnose: DiagnoseViewModel = { shortDescription: "", description: {html: "<p>diagnose</p>"}, departmentId: ""}
</script>

<h3>{diagnose.shortDescription}</h3>

{#if diagnose.description && diagnose.description.html}
    <p>{@html diagnose.description.html}</p>
{:else}
    <p>Ingen beskrivelse</p>
{/if}