<script lang="ts">
    import type { AuthorizationLine, UserViewModel } from "@api"
    import { BdEntity } from "@api"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import rest from "@common/services/restService"
    import { fetchAllUsersAdmin } from "@common/store/userAdminStore"
    export let param: UserViewModel = {
        id: "",
        name: "...",
        email: "",
        provider: "password"
    }
    import { departments, getDepartment } from "@app/store/departmentStore"

    // BdEntity
    const entities: Array<string> = []
    for (let value in BdEntity) {
        if (isNaN(Number(value)))
            entities.push(value)
    }
    
    const setEnt = (line: AuthorizationLine, ent: string) => {
        line.entity = BdEntity[ent]
    }
    // const setDep = (depId) => {
    //     newAuthLine.entityId = depId
    // }
    const addAuth = () => {
        let newAuthLine: AuthorizationLine = {}
        if (!param.authorizationData)
            param.authorizationData = { lines: []}
        if (!param.authorizationData.lines)
            param.authorizationData.lines = []
        param.authorizationData.lines = [...param.authorizationData.lines, newAuthLine]
    }
    const update = async () => {
        try {
            await rest.put("user", param)
            toast.success(`${lang("user")} ${lang("saved")}`)
            fetchAllUsersAdmin()
        } catch (ex)  {
            console.log(ex.message)
        }
    }
</script>

<style>
    
</style>

<h4>{lang('authorization')}</h4>

{#if param.authorizationData && param.authorizationData.lines}
    {#each param.authorizationData.lines as line}
        <mwc-select label="Entitet" on:change={e => setEnt(line, e.target.value)}>
            <mwc-list-item></mwc-list-item>
            {#each entities as e}
                <mwc-list-item
                    value="{e}"
                    selected={line.entity == BdEntity[e]}>
                    {lang(e.toLowerCase())}
                </mwc-list-item>
            {/each}
        </mwc-select>
        <mwc-select label="{lang('department')}" on:change={e => line.entityId = e.target.value}>
            <mwc-list-item></mwc-list-item>
            {#each $departments as d}
                <mwc-list-item 
                    value="{d.id}"
                    selected={line.entityId == d.id}>
                    {d.name}
                </mwc-list-item>
            {/each}
        </mwc-select>
        <br>
    {/each}
{:else}
    <p>Ingen autorisering satt for denne brukeren</p>
{/if}

<br>
<button on:click|preventDefault={addAuth}>{lang('add')}</button>
<br>
<button on:click|preventDefault={update}>{lang('save')}</button>