<script lang="ts">
    import { onMount } from "svelte"
    import rest from "@common/services/restService"
    import type { SubscriptionState } from "@api"
    import "./MembershipsSortable"

    let list: SubscriptionState[] = []
    onMount(async () => {
        list = await rest.get<SubscriptionState[]>("PaymentApi/subscriptionall")
    })
</script>

<article class="default">
    <header>
        <h2>Alle som er medlem eller har vært</h2>
    </header>
    <section>
        <membership-table-sortable memberships={list}></membership-table-sortable>
    </section>
</article>