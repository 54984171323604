
import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import {imageAssetStore} from "@common/store/assetsStore"
import { observe } from "@common/directives/ObservableDirective"
import type { ImageFileGroupViewModel } from "@api"
@customElement('asset-list-selector')
export class imageViewer extends LitElement {
    
    static styles = css`
        :host {
            display: block;
        }
    `

    render() {
        return html`
            ${observe(imageAssetStore, (assets: ImageFileGroupViewModel[]) => {
                return html`<image-list-selector .images=${assets} ></image-list-selector>`
            })}
            
        `
    }
}