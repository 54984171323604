<script lang="ts">
    import type { PartnerViewModel } from "@api"
    export let partner: PartnerViewModel
    import EditRichText from "@common/components/RichText/EditRichText.svelte"
    
</script>
<style>
    mwc-textfield {
        width: 100%;
    }
</style>
<div class="header-values">
    <mwc-textfield label="Navn" value={partner.name} on:change={e => partner.name = e.target.value}></mwc-textfield><br>
    <mwc-textfield label="Link" value={partner.externalLink} on:change={e => partner.externalLink = e.target.value}></mwc-textfield>
</div>
    
<EditRichText model={partner.description} />
