<script lang="ts">
    import lang from "@common/services/lang"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"

    export let id: string
    export let name: string
    export let remove: (id: string) => void
    let dialogState = false
    
</script>
<style>
    :host {
        display: inline-block;
    }
</style>
<ModalDialog open="{dialogState}">

    <div slot="btnContent">
        <i class="material-icons mdc-button__icon" aria-hidden="true" on:click={() => dialogState = true}>delete_forever</i>
    </div>
    <div class="fileframe" slot="dlgContent">
        <p>
            Vil du slette tjenesten <strong>{name}</strong>?<br>
            Det går ikke an dersom den er i bruk av mekanikere og ordrer
        </p>
        <p>
            
            <button on:click={() => { remove(id); dialogState = false}}>{lang("remove")}</button>
            <button class="secondary-button" on:click={() => dialogState = false}>{lang("cancel")}</button>
        </p>
    </div>
</ModalDialog>