<script lang="ts">
    
    import "firebase/storage"
    import Cropper from "cropperjs"
    import "cropperjs/dist/cropper.min.css"
    import { onMount, onDestroy } from "svelte"
    
    import {uploadImageFileToFirebase} from "@common/services/imageUploading"
    import lang from "@common/services/lang"
    import { faCamera, faImages, faVideo } from "@fortawesome/free-solid-svg-icons"
    import type { ImageFilePostModel, StorageFolder } from "@api"
    
    export let folder: StorageFolder
    export let subfolder = ""
    export let prefixName = ""
    export let onClose: (vm?: ImageFilePostModel) => void
    export let allowNotCrop = false
    export let inlineMode = false

    let isCropped = false
    let cropper: Cropper
    let previewEl: HTMLImageElement
    let selectedFile: File
    let uploadProgress = 0
    
    let uploadedFile: ImageFilePostModel

    let isDraggingOver = false
    let dropZoneEl: HTMLElement

    const cancel = () => {
        selectedFile = null
        unsetCropper()
    }
    const close = () => {
        onClose()
    }
    const save = async () => {
        
        let cropData = null
        if (isCropped) {
            cropData = cropper.getData(true)
        }
        uploadedFile.isCropped = isCropped
        uploadedFile.cropData = cropData
        
        unsetCropper()
        onClose(uploadedFile)
        
        selectedFile = null
        uploadProgress = 0
        isCropped = false
    }

    const onDrop = (ev: DragEvent) => {
        ev.preventDefault()

        if (ev.dataTransfer.items) {
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === "file") {
                    let file = ev.dataTransfer.items[i].getAsFile()
                    onFileSelected(file)
                }
            }
        } else {
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                let file = ev.dataTransfer.files[i]
                onFileSelected(file)
            }
        }
        isDraggingOver = false
    }
    const onDragEnter = (e: Event) => {
        e.preventDefault()
        isDraggingOver = true
    }
    const onDragOver = (e: Event) => {
        e.preventDefault()
        isDraggingOver = true
    }
    const onDragLeave = (e: Event) => {
        e.preventDefault()
        isDraggingOver = false
    }
    
    onMount(() => {
        dropZoneEl.addEventListener("drop", onDrop)
        dropZoneEl.addEventListener("dragenter", onDragEnter)
        dropZoneEl.addEventListener("dragover", onDragOver)
        dropZoneEl.addEventListener("dragleave", onDragLeave)
    })
    onDestroy(() => {
        dropZoneEl.removeEventListener("drop", onDrop)
        dropZoneEl.removeEventListener("dragenter", onDragEnter)
        dropZoneEl.removeEventListener("dragover", onDragOver)
        dropZoneEl.removeEventListener("dragleave", onDragLeave)
    })
    
    const onFileInputChanged = (e) => {
        const inputField = e.target
        if (inputField.files && inputField.files[0]) {
            const file = inputField.files[0]
            onFileSelected(file)
        }
    }
    
    const onFileSelected = async (file: File) => {
        selectedFile = file
        await uploadImageFileToFirebase(folder, subfolder, prefixName, selectedFile, 
        (progress: number) => { uploadProgress = progress },
        (model: ImageFilePostModel) => {
            uploadedFile = model
            setPreview(model)
            if (inlineMode) {
                save()
            }
        })
    }

    const setupCropper = () => {
        if (!cropper) {
            cropper = new Cropper(previewEl, {
                viewMode: 1,
                aspectRatio: 1/1
            })
        }
    }

    const unsetCropper = () => {
        if (cropper && cropper.destroy) {
            cropper.destroy()
            cropper = null
        }
    }

    const toggleCropper = () => {
        isCropped = !isCropped

        if (isCropped)
            setupCropper()
        else
            unsetCropper()
    }
    
    const setPreview = (model: ImageFilePostModel) => {
        previewEl.src = model.url
    }

</script>

<style>
    #drop-zone {
        width: 360px;
        max-width: 100%;
        height: 240px;

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-top: 1rem;
        padding: 1rem 0 0;

        outline: 2px dashed #92b0b3;
        color: #92b0b3;
        /* outline-offset: -10px; */

        font-size: 1.25rem;
        background-color: #c8dadf;
    }
    #drop-zone.dragOver {
        outline: 2px dashed var(--mdc-theme-primary);
        background-color: var(--mdc-theme-secondary);
    }
    
    .uploader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    input.noshow {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    img {
        display: none;
        max-width: 100%;
        max-height: 100%;
    }
    img.show {
        display: block;
    }
    
    label.upload-btn:hover {
        box-shadow: 0 0 40px 10px var(--mdc-theme-surface), 0 0 10px var(--mdc-theme-surface);
        top: 2px;
    }
    label.upload-btn {
        border-radius: 3px;
        cursor: pointer;
        padding: 0 0.5rem;
        transition: none;
    }
    fa-icon {
        color: var(--bildoktoren-blue);
        --bd-fa-icon-width: 3rem;
        --bd-fa-icon-height: 3rem;
    }
    #toggleCrop {
        cursor: pointer;
    }
    #toggleCrop.enabled {
        background: var(--mdc-theme-primary);
        color: var(--mdc-theme-on-primary);
        border-radius: 3px;
    }
    @media only screen and (max-width: 640px) {
        #drop-zone {
            height: 100px;
        }
        .uploader {
            flex-direction: row;
        }
        #droptext {
            display: none;
        }
    }
</style>
{#if selectedFile && allowNotCrop}
    <span class="material-icons" id="toggleCrop" 
        class:enabled={isCropped} aria-hidden="true" 
        title="{lang('crop')}" 
        on:click|preventDefault={toggleCropper}>crop</span>
{/if}
<img bind:this={previewEl} alt="preview" class:show={selectedFile}/>

{#if uploadProgress}
    <div class="upload-progress">{uploadProgress}%</div>
{/if}
{#if !selectedFile}
    <div id="drop-zone" bind:this={dropZoneEl} class:dragOver={isDraggingOver}>
        <div class="uploader">
            <label for="imageInput" class="upload-btn" title="{lang("select-file")}">
                <fa-icon icon={faImages}></fa-icon>
            </label>
            <input type="file" accept="image/*" 
                id="imageInput" 
                on:change={onFileInputChanged} 
                class="noshow" />
            <label for="cameraInput" class="upload-btn">
                <fa-icon icon={faCamera}></fa-icon>
            </label>
            <input id="cameraInput" type="file" 
                class="noshow"
                accept="image/*" 
                capture="environment" 
                on:change={onFileInputChanged} />
        </div>
        <div id="droptext">{lang("drop-files-here")}</div>
    </div>
    
    {#if !inlineMode}
        <p>
            <button on:click={close}>{lang("close")}</button>
        </p>
    {/if}
{:else}
    {#if !inlineMode}
        <p>
            <button on:click={cancel}>{lang("cancel")}</button>
            <button on:click={save}>{lang("save")}</button>
        </p>
    {/if}
{/if}

