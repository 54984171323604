import { DocumentState } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('state-icon')
export class StateIcon extends LitElement {
    
    static styles = css`
        :host {
            display: inline-block;
            width: 25px;
            height: 25px;
        }
    `
    
    @property({attribute: false})
    state: DocumentState = DocumentState.Open

    render() {
        this.setAttribute("title", this.state == DocumentState.Open ? "Åpen": "stengt for editering")
        return html`
            ${this.state == DocumentState.Open ? html`
                <span></span>
            ` : html`
            <mwc-icon>block</mwc-icon>
            `}
        `
    }
}