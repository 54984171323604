<script lang="ts">
    import {onMount} from "svelte"
    import DiagnoseForm from "./DiagnoseForm.svelte"
    import lang from "@common/services/lang"
    import { createDiagnose } from "@common/store/diagnoseStore"
    import { getDepsByWriteAccess } from "@app/store/departmentStore"
    import type { DiagnoseViewModel } from "@api"
    import { gotoPath } from "@common/routing/router"
    let diagnose: DiagnoseViewModel = {
        id: null,
        shortDescription: "",
        departmentId: "",
        registrationNumber: ""
    }
    let create = async () => {
        const created = await createDiagnose(diagnose)
        gotoPath(`/diagnoses/${created.id}/edit`)
    }

    let getDefaultDepartmentId = () => {
        let defaultDepartmentId = ""
        const deps = getDepsByWriteAccess()
        if (deps && deps.length > 0)
            defaultDepartmentId = deps[0].id
        return defaultDepartmentId
    }
    onMount(() => {
        const defaultDep = getDefaultDepartmentId()
        diagnose.departmentId = defaultDep
    })
</script>
<h2>Opprett ny diagnose</h2>
<DiagnoseForm diagnose={diagnose} />
<br>
<button on:click={create}>{lang('create')}</button>
