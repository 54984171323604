<script lang="ts">
    import firebase from "firebase"
    import "firebase/storage"
    import { onMount, onDestroy } from "svelte"
    import type { ImageFilePostModel, StorageFilePostModel, StorageFolder } from "@api"
    import service from "@common/services/fileStorage"
    import lang from "@common/services/lang"

    export let folder: StorageFolder
    export let subfolder = ""

    export let closeAfterUpload = true
    export let onClose: (vm?: StorageFilePostModel) => void
    export let inlineMode = true

    let selectedFile: File
    let uploadProgress = 0
    let fileInput: HTMLInputElement
    let uploadedFile: StorageFilePostModel

    let isDraggingOver = false
    let dropZoneEl: HTMLElement

    const cancel = () => {
        selectedFile = null
    };
    const close = () => {
        onClose()
    };
    const save = async () => {
        onClose(uploadedFile)
        selectedFile = null
        uploadProgress = 0
    };

    const onDrop = (ev: DragEvent) => {
        ev.preventDefault()

        if (ev.dataTransfer.items) {
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === "file") {
                    let file = ev.dataTransfer.items[i].getAsFile()
                    onFileSelected(file)
                }
            }
        } else {
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                let file = ev.dataTransfer.files[i];
                onFileSelected(file);
            }
        }
        isDraggingOver = false;
    };
    const onDragEnter = (e: DragEvent) => {
        e.preventDefault();
        isDraggingOver = true;
    };
    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        isDraggingOver = true;
    };
    const onDragLeave = (e: DragEvent) => {
        e.preventDefault();
        isDraggingOver = false;
    };
    
    onMount(() => {
        dropZoneEl.addEventListener("drop", onDrop);
        dropZoneEl.addEventListener("dragenter", onDragEnter);
        dropZoneEl.addEventListener("dragover", onDragOver);
        dropZoneEl.addEventListener("dragleave", onDragLeave);
    });
    onDestroy(() => {
        dropZoneEl.removeEventListener("drop", onDrop);
        dropZoneEl.removeEventListener("dragenter", onDragEnter);
        dropZoneEl.removeEventListener("dragover", onDragOver);
        dropZoneEl.removeEventListener("dragleave", onDragLeave);
    });
    
    const onFileInputChanged = () => {
        const file = fileInput.files[0];
        onFileSelected(file);
    };
    const onFileSelected = async (file: File) => {
        selectedFile = file;
        await uploadFile(selectedFile, () => {
            if (closeAfterUpload)
                save();
        });
    };
    
    const uploadFile = async (file: File, onDone: (model: ImageFilePostModel) => void) => {
        let { name, extension } = splitFileNameExtension(file.name);
        
        const filename =`${name}.${extension}`;

        console.log(`${file.name} as ${filename} to be uploaded`);
        const uploadTask = service.upload(folder, subfolder, filename, file);
        uploadTask.on("state_changed", (snapshot) => {
            uploadProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    console.log("Upload is paused");
                    break;
                case firebase.storage.TaskState.RUNNING:
                    console.log("Upload is running");
                    break;
            }
        }, (error) => {
            console.log(error);
        }, async () => {
            const fileRef = uploadTask.snapshot.ref;
            let url = await fileRef.getDownloadURL();
            const metaData = await fileRef.getMetadata();
            const { size } = file;
            const { contentType, bucket, md5Hash } = metaData;

            let model = {
                name,
                extension,
                contentType,
                bucket,
                folder,
                subfolder,
                url,
                md5Hash,
                size
            };
            uploadedFile = model;
            if (onDone)
                onDone(model);
        });
    };
    
    const splitFileNameExtension = (fileName: string) => {
        const split = fileName.split(".");
        let name = "";
        let extension = "";
        if (split.length == 1)
            name = split[0];
        else {
            const ext = split.splice(-1);
            extension = ext[0];
            name = split.join(".");
        }
        return { name, extension };
    };

</script>

<style>
    #drop-zone {
        width: 360px;
        max-width: 100%;
        height: 240px;

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-top: 1rem;
        padding: 1rem 0 0;

        outline: 2px dashed #92b0b3;
        color: #92b0b3;
        /* outline-offset: -10px; */

        font-size: 1.25rem;
        background-color: #c8dadf;
    }
    #drop-zone.dragOver {
        outline: 2px dashed var(--mdc-theme-primary);
        background-color: var(--mdc-theme-secondary);
    }
    
    .uploader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    input.noshow {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    
    .upload-link {
        padding: 0.5rem;
        border-radius: 3px;
        cursor: pointer;
        background-color: var(--mdc-theme-primary);
        color: var(--mdc-theme-on-primary);
        transition: none;
        margin-bottom: 0.5rem;
    }
    .upload-link:hover {
        box-shadow: 0 0 40px 10px var(--mdc-theme-surface), 0 0 10px var(--mdc-theme-surface);
        top: 2px;
    }

    @media only screen and (max-width: 640px) {
        #drop-zone {
            height: 100px;
        }
        #droptext {
            display: none;
        }
    }
</style>

{#if uploadProgress}
    <div class="upload-progress">{uploadProgress}%</div>
{/if}
{#if !selectedFile}
    <div id="drop-zone" bind:this={dropZoneEl} class:dragOver={isDraggingOver}>
        <div class="uploader">
            <label for="fileinput" class="upload-link">{lang("select-file")}</label>
            <input type="file"  
                id="fileinput"
                bind:this={fileInput} 
                on:change={onFileInputChanged} 
                class="noshow" /> <!-- should not show in the UI  -->
        </div>
        <div id="droptext">{lang("drop-files-here")}</div>
    </div>
    {#if !inlineMode}
        <p>
            <button on:click={close}>{lang("close")}</button>
        </p>
    {/if}
{:else}
    {#if !closeAfterUpload}
        <p>
            <button on:click={cancel}>{lang("cancel")}</button>
            <button on:click={save}>{lang("save")}</button>
        </p>
    {/if}
{/if}

