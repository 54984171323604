<script lang="ts">
    import type { SvelteComponent } from "svelte"
    import { curRoute } from "@common/routing/router"

    let component: SvelteComponent, param: string, action: string

    curRoute.subscribe(route => {
        component = route.component
        param = route.param
        action = route.action
    })

</script>

<style>
    .main-container {
        background: var(--mdc-theme-background);
        overflow-y: auto;
        display: flex;
        justify-content: center;
        min-height: 100%;
    }
    .center-container {
        width: 100%;
        min-height: 100%;
    }
</style>

<section class="main-container">

    <div class="center-container">
        {#if param}
            {#if action}
                <svelte:component this={component} param={param} action={action} />
            {:else}
                <svelte:component this={component} param={param} />
            {/if}
        {:else}
            <svelte:component this={component} />
        {/if}
    </div>
    
</section>
