<script lang="ts">
    import "./DiagnosesTableMini"
    import { lastFiveDiagnoses } from "@common/store/diagnoseStore"
    
</script>
<style>
    
    .diagnose-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list {
        display: flex;
        flex-direction: column;
    }
</style>
<section class="diagnose-list">
    <div class="list">
        <diagnose-table-mini diagnoses={$lastFiveDiagnoses}></diagnose-table-mini>
    </div>
</section>
