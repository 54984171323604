<script lang="ts">
    import lang from "@common/services/lang"
    import ButtonDialog from "@common/components/Dialogs/ButtonDialog.svelte"

    export let openState = false
    export let id: string
    export let name: string
    export let remove: (id: string) => void

</script>
<style>
    :host {
        display: inline-block;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
<ButtonDialog open="{openState}">
    <div class="fileframe" slot="dlgContent">
        <p>
            Vil du slette asset <strong>{name}</strong>?<br>
            Det går ikke an dersom den er i bruk
        </p>
        <p class="button-row">
            <button on:click={() => { remove(id); openState = false}}>{lang("remove")}</button>
            <button class="secondary-button" on:click={() => openState = false}>{lang("cancel")}</button>
        </p>
    </div>
</ButtonDialog>