<script lang="ts">
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import lang from "@common/services/lang"
    import { contents } from "@app/store/contentStore"
    
</script>
<style>
    
</style>
<!-- <div class="new">
    <ActionLink action="create" langCode="create" icon="create_new_folder" /> {lang("create-new")}
</div> -->
{#if $contents.length === 0}
    <p>Ingen innhold tilgjengelig</p>
{:else}
    <div class="list">
        {#each $contents as content}
            <div>
                <ActionLink param={content} action="edit" langCode="edit" icon="edit" />
                <span>{lang(content)}</span>
            </div>
        {/each}
    </div>
{/if}
