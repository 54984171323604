import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement, property} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { DiagnoseListModel } from "@api"

@customElement('diagnose-table-sortable')
export class DiagnosesTableSortable extends LitElement {
    _diagnoses: DiagnoseListModel[] = []
    _nameLabel = lang("name")
    _createdLabel = lang("created")
    _changedLabel = lang("last-changed")
    _depLabel = lang("department")
    
    _sortFunc = new SortFuncs()
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        
        .table-row:nth-child(even) {background: #CCC}
        .table-row:nth-child(odd) {background: #FFF}
        
        a.table-row:nth-child(even):hover, a.table-row:nth-child(odd):hover {
            background: var(--mdc-theme-secondary);
            color: var(--mdc-theme-text-primary-on-dark);
        }

        .table { display:table; }
        .table-header-group { display: table-header-group; }
        .table-row-group { display: table-row-group; }
        .table-row { display:table-row; }
        .table-cell {
            display: table-cell;
            padding: 3px;
        }
        
        a {
            text-decoration: none;
            color: var(--mdc-theme-text-primary-on-background);
        }

        .table-header-group .table-cell {
            font-weight: bold;
        }
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }

    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._diagnoses, column);
        this.sortByFn(sortFn);
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._diagnoses);
        let sorted = this._diagnoses.sort(fn);
        this._diagnoses = sorted;
        this.requestUpdate("diagnoses", oldVal);
    }
    
    set diagnoses(val: DiagnoseListModel[]) {
        let oldVal = Array.from(this._diagnoses);
        this._diagnoses = val;
        this.requestUpdate("diagnoses", oldVal)
    }

    get diagnoses(): DiagnoseListModel[] {
        return this._diagnoses;
    }

    @property({attribute: false})
    extended: boolean
    
    render() {
        return html`
        <div class="table">
            <div class="table-header-group">
                <div class="table-row">
                    <div class="table-cell sortable">Kort beskrivelse</div>
                    <!-- <div class="table-cell sortable">${this._depLabel}</div> -->
                    <div class="table-cell sortable" @click=${() => this.sort('registrationNumber')}>Reg.nr</div>
                    <div class="table-cell sortable" @click=${() => this.sort('customerId')}>Kunde</div>
                    <div class="table-cell sortable" @click=${() => this.sort('created')}>${this._createdLabel}</div>
                    <div class="table-cell sortable" @click=${() => this.sort('lastUpdated')}>${this._changedLabel}</div>
                    <div class="table-cell sortable">
                        <span class="material-icons" aria-hidden="true" title="${lang('image')}">image</span>
                    </div>
                    <div class="table-cell sortable">
                        <span class="material-icons" aria-hidden="true" title="${lang('file')}">attach_file</span>
                    </div>
                    ${this.extended ? html`
                        <div class="table-cell sortable" @click=${() => this.sort('userId')}>Mekaniker</div>
                    ` :  html``}
                    <div class="table-cell sortable" @click=${() => this.sort('state')}>Status</div>
                </div>
            </div>
            <div class="table-row-group">
                ${repeat(this.diagnoses, (d) => d.id, (d, index) => html`
                    <a @click=${goto} href="/diagnoses/${d.id}/edit" class="table-row">
                        <div class="table-cell">${d.shortDescription}</div>
                        <!-- <div class="table-cell">
                            ${d.departmentName}
                        </div> -->
                        <div class="table-cell">
                            <span>${d.registrationNumber}</span>
                        </div>
                        <div class="table-cell">
                            <user-id-viewer .userid=${d.customerId}></user-id-viewer>
                        </div>
                        <div class="table-cell">
                            <date-viewer .date=${d.created}></date-viewer>
                        </div>
                        <div class="table-cell">
                            <date-viewer .date=${d.lastUpdated}></date-viewer>
                        </div>
                        <div class="table-cell">
                            ${d.picturesCount}
                        </div>
                        <div class="table-cell">
                            ${d.attachmentsCount}
                        </div>
                        ${this.extended ? html`
                            <div class="table-cell">
                                <user-id-viewer userid="${d.userId}" .showEmail=${false}></user-id-viewer>
                            </div>
                        ` :  html``}
                        <div class="table-cell">
                            <state-icon .state=${d.state}></state-icon>
                        </div>
                    </a>
                `)}
                
                </a>
            </div>
        </div>
        `
    }
}