<script lang="ts">
    export let param = ""
    
    import { departments } from "@app/store/departmentStore"
    import lang from "@common/services/lang"
    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import DepInfo from "./DepartmentInfo.svelte"
    import Link from "@common/components/Nav/Link.svelte"
    //import OpenStreetMap from "../../../components/Maps/OpenStreetMap.svelte"
</script>
<style>
    

</style>
<article class="default">
    <header>
        <Link route="/departments">
            <h3>{lang('departments')}</h3>
        </Link>
    </header>
    <section class="split-in-two">
        <div class="split subcontent">
            <div>
                {#if param == "create"}
                    <Create />
                {:else if !param}
                    <List deps={$departments} />
                {:else}
                    <DepInfo param={param} />
                {/if}
                
            </div>
        
        </div>
        <div class="split">
            <!-- <OpenStreetMap areas={areas} zoom={4}/> -->
        </div>
    </section>

</article>
