<script lang="ts">
    import type { ContentPostModel } from "@api"
    import { ImageSizeType } from "@api"
    export let model: ContentPostModel = { content: { assetContents: [], interval: 0}}
    import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"

    let activeIndex = 0
    let selectDialogOpen = false
    let openSelector = (index) => {
        activeIndex = index
        selectDialogOpen = true
    }
    const add = () => {
        model.content.assetContents = [...model.content.assetContents, { assetId: "", text: "", link: ""}]
    }
    const remove = (i) => {
        model.content.assetContents.splice(i, 1)
        model.content.assetContents = model.content.assetContents
    }
    let onImageSelected = (e) => {
        let id = e.detail.id
        let assetContent = model.content.assetContents[activeIndex]
        assetContent.assetId = id
        selectDialogOpen = false
        model.content.assetContents = model.content.assetContents
    }
</script>
<style>
    .list {
        display: flex;
        flex-direction: column;
    }
    .image-select {
        display: inline-block;
        cursor: pointer;
    }
    .no-image {
        display: inline-block;
        margin: 2rem 0;
    }
</style>
<mwc-textfield label="intervall sekunder" value={model.content.interval} on:change={e => model.content.interval = e.target.value}></mwc-textfield>

<button on:click={add}>Legg til</button>
<br>
<div class="list">
    {#each model.content.assetContents as ac, i}
        <div>
            <div class="image-select" on:click={() => (openSelector(i))}>
                {#if ac.assetId && ac.assetId != "00000000-0000-0000-0000-000000000000"}
                    <asset-image-viewer id={ac.assetId} wantedsize={ImageSizeType.Thumbnail}>
                    </asset-image-viewer>
                {:else}
                    <div class="no-image">Ingen bilde satt, trykk for å velge</div>
                {/if}
            </div>
            
            <mwc-textfield label="text" value={ac.text} on:change={e => ac.text = e.target.value}></mwc-textfield>
            <mwc-textfield label="link" value={ac.link} on:change={e => ac.link = e.target.value}></mwc-textfield>
            <fa-icon icon={faTrashAlt} on:click={() => remove(i)}></fa-icon>
        </div>
    {/each}
</div>

<ModalDialog open="{selectDialogOpen}">
    <div slot="btnContent"></div>
    <div class="picframe" slot="dlgContent">
        <asset-list-selector on:imageselected={onImageSelected}></asset-list-selector>
    </div>
</ModalDialog>