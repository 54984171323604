<script lang="ts">
    import type { Subscription } from "rxjs"
    import { onMount, onDestroy } from "svelte"
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import DeleteDialog from "./DeleteDialog.svelte";
    import lang from "@common/services/lang"
    import { productStore, deleteProduct } from "@app/store/productStore"
    import type { ProductViewModel } from "@api"
import { goto } from "@common/routing/router";
    
    let sub: Subscription
    let products: ProductViewModel[] = []
    onMount(() => {
        sub = productStore.subscribe(value => {
            products = value
        })
    })
    onDestroy(() => sub.unsubscribe())
    let remove = (id:string) => {
        console.log(`Remove ${id}`)
        deleteProduct(id)
    }

</script>
<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
    .row {
        display: flex;
        flex-direction: row;
    }
</style>
<div class="new">
    <ActionLink action="create" langCode="create" icon="create_new_folder" /> {lang("create-new")}
</div>
<div class="list">
    {#each products as p}
        <div class="row">
            <a on:click={goto} href="/products/{p.id}/edit">
                <span class="material-icons" aria-hidden="true" title="${lang('edit')}">edit</span>
            </a>
            <span>{p.name}</span>
            <small>&nbsp;(endret <date-viewer date={p.lastUpdated}></date-viewer>)</small>
            <DeleteDialog id={p.id} name={p.name} remove={remove}/>
            
        </div>
    {/each}
</div>