<script lang="ts">
    export let param: string
    import { getProduct, updateProduct } from "@app/store/productStore"
    import lang from "@common/services/lang"
    import ProductForm from "./ProductForm.svelte"
    import { gotoPath } from "@common/routing/router"
    import type { ProductViewModel } from "@api"
    
    let product: ProductViewModel = {
        routeKey: "",
        id: "",
        name: "",
        order: 1,
        description: { html: "", json: ""},
        variants: []
    }
    let get = async () => {
        try {
            product = await getProduct(param)
            if (!product.description)
                product.description = { html: "", json: ""}
        } catch (err) {
            errorHandler(err)
        }
    }
    
    //let errorMsg: string
    let update = async () => {
        //errorMsg = ""
        try {
            await updateProduct(product)
            gotoPath("/products")
        } catch (ex)  {
            errorHandler(ex)
        }
    }
    
    let errorHandler = (ex: Error) => {
        //errorMsg = ex.message.substring(0,500)
        console.log(ex.message)
    }

    $: if (param) {
        get()
    }
</script>

    <ProductForm product={product} />
    <button on:click={update}>{lang('save')}</button>
