<script lang="ts">
    import { createPost } from "@app/store/blogStore"
    import lang from "@common/services/lang"
    import { gotoPath } from "@common/routing/router"
    import type { BlogPostViewModel } from "@api"
    import type { BdEditorState } from "@common/models/interfaces"

    let newPost: BlogPostViewModel | null = {headline:"", body: {html: "", json: ""}, isDraft: true, order: 0, onFrontPage: false}
    const stateupdate = (evt: CustomEvent<BdEditorState>) => {
        newPost.body.html = evt.detail.html
        newPost.body.json = evt.detail.json
    }

    let create = async () => {
        try {
            await createPost(newPost)
            gotoPath("/blog")
        } catch (ex) {
            errorHandler(ex)
        }
    }
    
    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }
</script>

<input type="text" bind:value={newPost.headline} placeholder="{lang('headline')}" /><br>
<prose-editor html="<p></p>" on:stateupdate={stateupdate}></prose-editor>
<button on:click={create}>{lang('create')}</button>