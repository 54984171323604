<script lang="ts">
    export let diagnose: DiagnoseViewModel
    import lang from "@common/services/lang"
    import { userAuthInfo } from "@common/store/userProfile"
    import {createEventDispatcher} from "svelte"
    import type { DiagnoseViewModel } from "@api"
    import { DocumentState } from "@api"
    import { depsByWriteAccess } from "@app/store/departmentStore"
    import { setDiagnoseState} from "@common/store/diagnoseStore"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"
    const dispatch = createEventDispatcher()
    let emptyGuid = "00000000-0000-0000-0000-000000000000"
    let selectDialogOpen = false
    let disabled = false
    const setDep = (depId) => {
        diagnose.departmentId = depId
    }
    $ : if (diagnose) {
        disabled = diagnose.state == DocumentState.Closed
    }
    const onUserSelected = (e) => {
        let id = e.detail.id
        diagnose.customerId = id
        selectDialogOpen = false
        dispatch('userselected', {id})
    }

    const setState = async (state: DocumentState) => {
        diagnose = await setDiagnoseState(diagnose.id, state)
    }

    const openCustomerDialog = () => {
        if (diagnose.state == DocumentState.Open)
            selectDialogOpen = true
    }
</script>
<style>
    mwc-list-item {
        z-index: 100;
    }
    .headerline {
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }
    .customeruser {
        padding: 0.5rem 0;
        cursor: pointer;
    }
    @media only screen and (max-width: 640px) {
        .headerline {
            flex-wrap: wrap;
        }
    }
    mwc-textfield {
        flex: 1 1 auto;
    }
</style>

<div class="headerline">
    <!-- {#if diagnose.state != undefined && diagnose.state != null}
        <state-viewer state={diagnose.state}></state-viewer>
    {/if} -->
    {#if $userAuthInfo.isAdmin}
        {#if diagnose.state == DocumentState.Open}
            <bd-button on:click={() => setState(DocumentState.Closed)}>Steng</bd-button>
        {:else}
            <bd-button on:click={() => setState(DocumentState.Open)}>Åpne</bd-button>
        {/if}
    {/if}
    <mwc-select label="{lang('department')}" on:change={e => setDep(e.target.value)} {disabled}>
        {#each $depsByWriteAccess as d}
            <mwc-list-item 
                value="{d.id}"
                selected={d.id == diagnose.departmentId}>
                {d.name}
            </mwc-list-item>
        {/each}
    </mwc-select>

    <mwc-textfield label="kort beskrivelse" 
        value={diagnose.shortDescription} 
        on:change={e => diagnose.shortDescription = e.target.value}
        {disabled}>
    </mwc-textfield>
    
</div>

<div class="customeruser" on:click={openCustomerDialog}>
    <label for="user"><b>Kunde</b></label>
    {#if !diagnose.customerId || diagnose.customerId == emptyGuid}
        <button>Velg kunde</button>
    {:else}
        <user-id-viewer id="user" userid="{diagnose.customerId}"></user-id-viewer>
    {/if}
</div>


<div>
    <mwc-textfield label="Reg. nummer" 
        value={diagnose.registrationNumber}
        on:change={e => diagnose.registrationNumber = e.target.value}
        {disabled}>
    </mwc-textfield>
</div>

<ModalDialog bind:open={selectDialogOpen}>
    <div slot="btnContent"></div>
    <div slot="dlgContent">
        <users-list-selector on:userselected={onUserSelected}></users-list-selector>
    </div>
</ModalDialog>