import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto, gotoPath } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"

import type { DiagnoseListModel } from "@api"

@customElement('diagnose-table-mini')
export class DiagnosesTableSortable extends LitElement {
    _diagnoses: DiagnoseListModel[] = []
    _nameLabel = lang("name")
    _createdLabel = lang("created")
    _changedLabel = lang("last-changed")
    _depLabel = lang("department")
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        
        .table-row:nth-child(even) {background: #CCC}
        .table-row:nth-child(odd) {background: #FFF}
        
        a.table-row:nth-child(even):hover, a.table-row:nth-child(odd):hover {
            background: var(--mdc-theme-secondary);
            color: var(--mdc-theme-text-primary-on-dark);
        }

        .table { display:table; }
        .table-header-group { display: table-header-group; }
        .table-row-group { display: table-row-group; }
        .table-row { display:table-row; }
        .table-cell {
            display: table-cell;
            padding: 3px;
        }
        
        a {
            text-decoration: none;
            color: var(--mdc-theme-text-primary-on-background);
        }

        .table-header-group .table-cell {
            font-weight: bold;
        }
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }
    
    set diagnoses(val: DiagnoseListModel[]) {
        let oldVal = Array.from(this._diagnoses);
        this._diagnoses = val;
        this.requestUpdate("diagnoses", oldVal)
    }

    get diagnoses(): DiagnoseListModel[] {
        return this._diagnoses;
    }

    render() {
        return html`
        <div class="table">
            <div class="table-header-group">
                <div class="table-row">
                    <div class="table-cell">Beskrivelse</div>
                    <div class="table-cell">Reg.nr</div>
                    <!-- <div class="table-cell">Kunde</div> -->
                    <div class="table-cell">${this._createdLabel}</div>
                    <div class="table-cell">
                        <span class="material-icons" aria-hidden="true" title="${lang('image')}">image</span>
                    </div>
                    <div class="table-cell">
                        <span> </span>
                    </div>
                </div>
            </div>
            <div class="table-row-group">
                ${repeat(this.diagnoses, (d) => d.id, (d, index) => html`
                    <a @click=${goto} href="/diagnoses/${d.id}/edit" class="table-row">
                        
                        <div class="table-cell">${d.shortDescription}</div>
                        <div class="table-cell">
                            <span>${d.registrationNumber}</span>
                        </div>
                        <!-- <div class="table-cell">
                            <user-id-viewer .userid=${d.customerId}></user-id-viewer>
                        </div> -->
                        <div class="table-cell">
                            <date-viewer .date=${d.created}></date-viewer>
                        </div>
                        <div class="table-cell">
                            ${d.picturesCount}
                        </div>
                        <div class="table-cell">
                            <state-icon .state=${d.state}></state-icon>
                        </div>
                    </a>
                `)}
                
                </a>
            </div>
        </div>
        
        `
    }
}