<script lang="ts">
    import lang from "@common/services/lang"
    import { updateDepartment } from "@app/store/departmentStore"
    import DepartmentForm from "./DepartmentForm.svelte"
    import AssetDialog from "./AssetsDialog.svelte"
    import type { DepartmentViewModel } from "@api"
    import type { ImageFileGroupViewModel } from "@api"

    export let param: DepartmentViewModel =  {
        id: "",
        name: "...",
        routeKey: "",
        mapLookupName: "",
        location: { lat: 0, lng: 0 },
        googlePlaceId: ""
    }

    let update = async () => {
        updateDepartment(param)
    }
    let setImg = (image: ImageFileGroupViewModel) => {
        param.mainPicture = image
        param.mainPictureId = image.id
    }
    
</script>

<DepartmentForm department={param} />
{#if param.mainPicture}
    <h4>{lang("main-picture")}</h4>
    <image-viewer image={param.mainPicture} alt="main"></image-viewer>
{/if}
<AssetDialog onSetImage={setImg} />

<button on:click={update}>{lang('save')}</button>