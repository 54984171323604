import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement, property} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { PartnerViewModel } from "@api"
import { PartnerType } from "@api"

@customElement('partner-table-sortable')
export class PartnerTableSortable extends LitElement {
    _partners: PartnerViewModel[] = []
    _nameLabel = lang("name")
    _createdLabel = lang("created")
    _changedLabel = lang("last-changed")
    
    _sortFunc = new SortFuncs()
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        table { border-spacing: 5px; }
        tr:nth-child(even) {background: #CCC}
        tr:nth-child(odd) {background: #FFF}
        
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }

    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._partners, column);
        this.sortByFn(sortFn);
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._partners);
        let sorted = this._partners.sort(fn);
        this._partners = sorted;
        this.requestUpdate("diagnoses", oldVal);
    }
    
    set partners(val: PartnerViewModel[]) {
        let oldVal = Array.from(this._partners);
        this._partners = val;
        this.requestUpdate("partners", oldVal)
    }

    get partners(): PartnerViewModel[] {
        return this._partners;
    }

    render() {
        return html`
        <table>
            <thead>
                <th></th>
                <th class="sortable" @click="${() => this.sort('name')}">${this._nameLabel}</th>
                <th class="sortable" @click=${() => this.sort('type')}>Type</th>
                <th class="sortable" @click="${() => this.sort('municipality')}">Kommune</th>
            </thead>
            <tbody>
            
                ${repeat(this.partners, (d) => d.id, (d, index) => html`
                    <tr>
                        <td>
                            <a @click=${goto} href="/partners/${d.id}/edit">
                                <span class="material-icons" aria-hidden="true" title="${lang('edit')}">edit</span>
                            </a>
                        </td>
                        <td>
                            ${d.name}
                        </td>
                        <td>
                            ${lang(PartnerType[d.type])}
                        </td>
                        <td>
                            ${d.municipality}
                        </td>
                        
                    </tr>
                `)}
            
            </tbody>
        </table>
        `;
    }
}