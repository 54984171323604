<script lang="ts">
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import { updateDepartment } from "@app/store/departmentStore"
    import type { DepartmentViewModel } from "@api"
    import { cloneDeep } from "lodash-es"
    
    export let param: DepartmentViewModel
    
    let addLink = () => {
        if (!param)
            return
        
        if (!param.productLinks || param.productLinks.length == 0)
            param.productLinks = [{url: "url", description: "description"}]    
        else
            param.productLinks = [...param.productLinks, {url: "url", description: "description"}]
    }
    let update = async () => {
        const dep = cloneDeep(param)
        await updateDepartment(dep)
        toast.success(`${lang("products")} ${lang("saved")}`)
    }
    let remove = (index) => {
        param.productLinks.splice(index, 1)
        param.productLinks = param.productLinks
    }
</script>
<style>
    .products {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .delete {
        cursor: pointer;
    }
</style>

<h4>{lang('product-links')}</h4>
{#if param.productLinks}
    {#each param.productLinks as link, i}
        <div class="products">
            
            <mwc-textfield label="Link {i+1}" value={link.url} on:change={e => link.url = e.target.value}></mwc-textfield>
            <mwc-textfield label="Produkt {i+1}" value={link.description} on:change={e => link.description = e.target.value}></mwc-textfield>
            <div class="delete" title="slette?">
                <i class="material-icons mdc-button__icon" aria-hidden="true" on:click={() => remove(i)}>delete_forever</i>
            </div>
        </div>
    {/each}
    <br>
{/if}
<button on:click|preventDefault={addLink}>{lang('add')}</button>
<br>
<button on:click|preventDefault={update}>{lang('save')}</button>
