<script lang="ts">
    import type { ContentStaticIdentifier } from "@api"
    export let param: string
    export let action = ""
    import lang from "@common/services/lang"
    import Link from "@common/components/Nav/Link.svelte"
    import List from "./List.svelte"
    import Edit from "./Edit.svelte"

</script>

<article class="default">
    <header>
        <Link route="/content">
            <h3>{lang('contents')}</h3>
        </Link>
    </header>
    <section>
        
        {#if action == "edit"}
            <Edit param={param} />
        {:else}
            <List />
        {/if}
        
    </section>

</article>