<script lang="ts">
    import type { PartnerViewModel } from "@api"
    export let partner: PartnerViewModel
    import geocoder from "@common/services/googleGeocoder"
    import lang from "@common/services/lang"
    let geocode = async () => {
        if (partner.mapLookupName) {
            try {
                const res = await geocoder.geocodeAddress(partner.mapLookupName)
                partner.location = res.location
                partner.googlePlaceId = res.placeId
            } catch (err) {
                console.log(err)
            }
        }
    }
</script>
<style>
    mwc-textfield {
        width: 100%;
    }
    mwc-textfield.lookup {
        width: 50%;
    }
    .location {
        width: 100px;
    }
</style>
<mwc-textfield label="Kommune" value={partner.municipality} 
    on:change={e => partner.municipality = e.target.value}></mwc-textfield>
<br>

<label for="location">{lang('location')}</label><br>
<mwc-textfield class="lookup" label="kart lookup" value={partner.mapLookupName} 
    on:change={e => partner.mapLookupName = e.target.value}></mwc-textfield>
<button on:click={geocode}>Geocode</button>
<br>

<input type="text" id="location" bind:value={partner.location.lat} class="location" placeholder="Latitude">
<input type="text" bind:value={partner.location.lng} class="location" placeholder="Longitude">
