<script lang="ts">
    
    import {onMount} from "svelte"
    import "./DiagnosesTable"
    import type { DiagnoseViewModel } from "@api"
    import { fetchAllAdmin } from "@common/store/diagnoseStore"
    export let diagnoses: DiagnoseViewModel[] = []

    let getAll = async () => {
        diagnoses = await fetchAllAdmin()
    }

    onMount(() => getAll())
</script>
<style>
    
    .diagnose-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list {
        display: flex;
        flex-direction: column;
    }
</style>
<section class="diagnose-list">
    <div class="list">
        <diagnose-table-sortable diagnoses={diagnoses} extended={true}></diagnose-table-sortable>
    </div>
</section>
