import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { ImageFileGroupViewModel } from "@api"
import { ImageSizeType } from "@api"

@customElement('image-list-selector')
export class imageViewer extends LitElement {

    static styles = css`
        .list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .image-wrapper {
            flex-basis: 320px;
            flex-grow: 0;
            flex-shrink: 1;
            cursor: pointer;
        }
        .image-wrapper:hover {
            box-shadow: var(--bd-box-shadow-hover);
        }
    `
    @property({attribute: false})
    images: ImageFileGroupViewModel[]

    emitSelectEvent(id: string) {
        var evt = new CustomEvent('imageselected', {
            bubbles: true,
            composed: true,
            detail: { id }
        })
        this.dispatchEvent(evt)
    }
    render() {
        if (!this.images)
            return html`<p>Ingen assets</p>`
        
        return html`
            <div class="list">
                ${this.images.map(image => {
                    return html`
                        <div class="image-wrapper" @click=${() => this.emitSelectEvent(image.id)}>
                            <image-viewer class="thumbnail" .image=${image} .wantedsize=${ImageSizeType.Thumbnail} alt=${image.name}></image-viewer>
                        </div>
                    `
                })}
            </div>
        `
    }
}