<script lang="ts">
    export let param: string
    import restService from "@common/services/restService"
    import { currentLocation } from "@common/store/state"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import LoadingIndicator from "@common/components/LoadingIndicator.svelte"
    import AreaForm from "./AreaForm.svelte"
    import type { AreaViewModel } from "@api"
    let area: AreaViewModel = {
        id: "",
        name: "...",
        mapLookupName: "",
        location: { lat: 0, lng: 0 },
        legacyLink: "",
        googlePlaceId: ""
    }
    // $: if (param) {
    //     getArea()
    // }
    let getArea = async () => {
        try {
            area = await restService.get(`area/${param}`)
            broadcastCurrent(area.location.lat, area.location.lng, area.name)
        } catch (err) {
            errorHandler(err)
        }
    }
    let broadcastCurrent = (lat: number, lng: number, title: string) => {
        currentLocation.set({ lat, lng, title })
    }
    
    let updateArea = async () => {
        const url = "area"
        try {
            await restService.put(url, area)
            toast.success(`${lang("area")} ${lang("saved")}`)
        } catch (ex)  {
            errorHandler(ex)
        }
    }
    
    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }
</script>
<AreaForm area={area} />
{#await getArea()}
    <LoadingIndicator />
{:catch error}
    <p style="color: red">{error.message}</p>
{/await}
<button on:click={updateArea}>{lang('save')}</button>