<script lang="ts">
    import rest from "@common/services/restService"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import UserForm from "./UserForm.svelte"
    import type { UserViewModel } from "@api"
    import { fetchAllUsersAdmin } from "@common/store/userAdminStore"

    let newuser: UserViewModel = {
        email: "",
        name: ""
    }
    
    const create = async () => {
        try {
            await rest.post("user", newuser)
            toast.success(`${lang("user")} ${lang("saved")}`)
            fetchAllUsersAdmin()
        } catch (ex)  {
            errorHandler(ex)
        }
    }

    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }
</script>

<UserForm user={newuser} />

<button on:click|preventDefault={create}>Opprett</button>
<br>
