import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { UserViewModel } from "@api"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"

@customElement('application-viewer')
export class ApplicationViewer extends LitElement {

    static styles = css`
        :host {
            display: flex;
        }
        ${unsafeCSS(materialIconsStyle)}
        span.started {
            color: var(--bildoktoren-blue);
            font-size: 20px;
        }
        span.pending {
            color: var(--success);
        }
    `;

    @property({attribute: false})
    user: UserViewModel
    
    getApplicationIcon(u) {
        let icon = "";
        let langu = "";
        let css = "";
        if (u.startedApplication) {
            langu = lang("application-started");
            icon = "assignment";
            css = "started";
        }
        if (u.pendingApplication) {
            icon = "assignment";
            langu = lang("application-sent");
            css = "pending";
        }
        if (langu) {
            return html`
                <span class="material-icons ${css}" aria-hidden="true" title=${langu}>${icon}</span>
            `;
        }
        return null;
    }

    render() {
        const appl = this.getApplicationIcon(this.user);
        return html`
            ${appl}
        `;
    }
}