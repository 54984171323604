<script lang="ts">
    export let diagnose: DiagnoseViewModel
    import type { DiagnoseViewModel } from "@api"
    import {DocumentState} from "@api"
    import EditRichText from "@common/components/RichText/EditRichText.svelte"
    import { updateDiagnose } from "@common/store/diagnoseStore"
    import lang from "@common/services/lang"
    const save = async () => {
        await updateDiagnose(diagnose)
    }
</script>
<style>
    
</style>

{#if diagnose.state != DocumentState.Closed}
    <div>
        <EditRichText model={diagnose.description} />
    </div>
    <div>
        <button on:click={save}>{lang('save')}</button>
    </div>
{:else}
    <div>
        {@html diagnose.description.html}
    </div>
{/if}