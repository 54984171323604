<script lang="ts">
    //export let param = ""
    import List from "./List.svelte"
    import lang from "@common/services/lang"
    import Link from "@common/components/Nav/Link.svelte"
    
</script>
<style>
    
</style>
<article class="default">
    <header>
        <Link route="/assets">
            <h2>{lang('admin-assets')}</h2>
        </Link>
    </header>
    <section>
        <List />
    </section>

</article>
