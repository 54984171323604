<script lang="ts">
    let dialogEl: HTMLDivElement
    export let open = false
    export let background = "--mdc-theme-background"

    export let onClose = null
    let closeDlg = () => {
        open = false
        if (onClose)
            onClose()
    }
</script>
<style>
    div.modal-dialog {
        display: none;
        position: fixed;
        z-index: 900;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }
    div.modal-dialog.open {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal-content {
        position: relative;
        border-radius: 6px;
        margin: auto;
        padding: 20px;
    }
    .close-btn {
        --mdc-theme-secondary: var(--background-almost-transparent);
        position: absolute;
        top: -24px;
        right: -24px;
    }
</style>
<div>
    <slot name="btnContent">Open dlg</slot>
</div>
<div class="modal-dialog" bind:this={dialogEl} class:open>
    <div class="modal-content" style="background: var({background})">
        <mwc-fab mini class="close-btn" 
            icon="close"
            on:click={closeDlg}>
        </mwc-fab>
        <slot name="dlgContent">dialog content</slot>
    </div>
</div>