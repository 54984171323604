<script lang="ts">
    let dialogEl: HTMLDivElement;
    export let open = false;
    export let background = "--mdc-theme-background";
</script>
<style>
    div.button-modal-dialog {
        display: none;
        position: fixed;
        z-index: 900;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }
    div.button-modal-dialog.open {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal-content {
        border-radius: 6px;
        margin: auto;
        padding: 20px;
    }
</style>

<div class="button-modal-dialog" bind:this={dialogEl}
    class:open >
    <div class="modal-content" style="background: var({background})">
        <slot name="dlgContent">dialog content</slot>
    </div>
</div>