<script lang="ts">
    import Link from "@common/components/Nav/Link.svelte"
    export let langCode = ""
    export let label = ""
    export let param = ""
    export let action = ""
    export let icon = ""
    export let onHover = null
    let append = ""

    $: if (param || action) {
        append = ""
        if (param) {
            append = `/${param}`
        }
        if (action) {
            append = `${append}/${action}`
        }
    }
</script>

<Link append={append} langCode={langCode} label={label} icon={icon} onHover={onHover} />