<script lang="ts">
    import { OrderStatus } from "@api"
    import type { OrderViewModel } from "@api"
    export let order: OrderViewModel = {
        id: "",
        orderNumber: "",
        created: new Date().toISOString(),
        noteFromBuyer: "",
        status: OrderStatus.Created,
        lines: []
    }
</script>
<h3>Edit</h3>
id: {order.id}<br>
no: {order.orderNumber}