<script lang="ts">
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import lang from "@common/services/lang"
    import {blogStore} from "@app/store/blogStore"
    
</script>
<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
</style>
<div class="new">
    <ActionLink action="create" langCode="create" icon="create_new_folder" /> {lang("create-new")}
</div>
{#if $blogStore.length === 0}
    <p>Laster...</p>
{:else}
    <div class="list">
        <blog-table-sortable posts={$blogStore}></blog-table-sortable>
    </div>
{/if}
