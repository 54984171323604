import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"
import rest from "@common/services/restService"
import { cloneDeep } from "lodash-es"
import type { BlogPostViewModel } from "@api"

const blogStoreSubject = new SvelteSubject<BlogPostViewModel[]>([])
export const blogStore = clonedObservable(blogStoreSubject)

const fetchPosts = async () => {
    const posts = await rest.get<BlogPostViewModel[]>("blog")
    blogStoreSubject.next(posts)
}
fetchPosts()

export const createPost = async (post: BlogPostViewModel) => {
    post = await rest.post<BlogPostViewModel>("blog", post)
    const current = blogStoreSubject.getValue()
    blogStoreSubject.next([...current, post])
    toast.success(`${lang("blogpost")} ${lang("created")}`)
}
export const getPost = async (id:string): Promise<BlogPostViewModel> => {
    let post = blogStoreSubject.value.find(p => p.id === id)
    if (!post)
        post = await rest.get<BlogPostViewModel>(`blog/${id}`)
    if (!post.body)
        post.body = { html: "", json: ""}
    if (!post.order) {
        post.order = 0
    }
    const postClone = cloneDeep(post)
    return postClone
}


export const updatePost = async(post: BlogPostViewModel) => {
    
    post = await rest.put("blog", post)
    const currList = blogStoreSubject.getValue()
    const index = currList.findIndex(p => p.id === post.id)
    if (index > -1) {
        const newValue = cloneDeep(currList)
        newValue.splice(index, 1, post)
        blogStoreSubject.next(newValue)
    }
    toast.success(`${lang("blogpost")} ${lang("saved")}`)
    return post
}