<script lang="ts">
    import type { ContentPostModel } from "@api"
    import type { BdEditorState } from "@common/models/interfaces"
    export let model: ContentPostModel = { content: { richText: { html: "", json: "" }}}

    let richTextInitial: BdEditorState = {
        html: "<p></p>",
        json: ""
    }
    const stateupdate = (evt: CustomEvent<BdEditorState>) => {
        if (!model.content.richText)
            model.content.richText = { json: "", html: ""}
        model.content.richText.html = evt.detail.html
        model.content.richText.json = evt.detail.json
    }
    let isInitialized = false
    $: if (!isInitialized && model && model.content && model.content.richText && model.content.richText.json) {
        isInitialized = true
        richTextInitial.html = model.content.richText.html ?? ""
        richTextInitial.json = model.content.richText.json ?? ""
    }
</script>

<div>
    <prose-editor html={richTextInitial.html} json={richTextInitial.json} on:stateupdate={stateupdate}></prose-editor>
</div>