<script lang="ts">
    import { currentLocation } from "@common/store/state"
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import lang from "@common/services/lang"
    import type { AreaViewModel } from "@api"
    export let areas: AreaViewModel[] = []

    currentLocation.set({ lat: 0, lng: 0, title: "" })

    let highlight = (area: AreaViewModel) => {
        currentLocation.set({ lat: area.location.lat, lng: area.location.lng, title: area.name })
    }
    
</script>
<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
    .areas-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list {
        display: flex;
        flex-direction: column;
    }
</style>
<section class="areas-list">
    <div class="new">
        <ActionLink action="create" langCode="create" icon="create_new_folder" /> {lang("create-new")}
    </div>
    <div class="list">
        {#each areas as area}
            <div on:mouseenter={() => highlight(area)}>
                <ActionLink param={area.id} action="edit" langCode="edit" icon="edit" />
                {area.name}
            </div>
        {/each}
    </div>
</section>
