<script lang="ts">
    
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import "./PartnersTable"
    import type { PartnerViewModel } from "@api"
    export let partners: PartnerViewModel[] = []

</script>
<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
    .diagnose-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list {
        display: flex;
        flex-direction: column;
    }
</style>
<section class="diagnose-list">
    <div class="new">
        <ActionLink action="create" langCode="create" icon="create_new_folder" />
    </div>
    <div class="list">
        <partner-table-sortable partners={partners}></partner-table-sortable>
    </div>
</section>
