import { SvelteSubject } from "@common/store/reactive"
import restCache from "@common/services/restCache"
import { cloneDeep } from "lodash-es"
import type { ProductViewModel } from "@api"
import SortFuncs from "@common/funcs/sortFuncs"
const sortFun = new SortFuncs()
const url = "product"

const productStoreSubject = new SvelteSubject<ProductViewModel[]>([])
export const productStore = productStoreSubject.asObservable()

const fetchProducts = async () => {
    const products = await restCache.get<ProductViewModel[]>(url)
    let sortFn = sortFun.getSortFn(products, "order");
    let sorted = products.sort(sortFn);
    productStoreSubject.next(sorted)
}

fetchProducts()

const getAll = async () => {
    const current = productStoreSubject.get()
    if (current.length === 0) {
        await fetchProducts()
    }
    return productStoreSubject.get()
}

export const getAllClone = async () => {
    const all = await getAll()
    const copy = cloneDeep(all)
    return copy
}

export const getProduct = async (id:string) => {
    let prod = productStoreSubject.getValue().find(p => p.id === id)
    if (!prod) {
        prod = await restCache.get<ProductViewModel>(`blog/${id}`)
    }

    var cloneProd = cloneDeep(prod)
    if (!cloneProd.variants)
        cloneProd.variants = []
    return cloneProd
}

// byroute
export const getProductByRoute = async (routeKey: string) => {
    let prod = productStoreSubject.getValue().find(p => p.routeKey === routeKey)
    if (!prod) {
        prod = await restCache.get<ProductViewModel>(`${url}/byroute/${routeKey}`)
    }

    var cloneProd = cloneDeep(prod)
    if (!cloneProd.variants)
        cloneProd.variants = []
    return cloneProd
}
