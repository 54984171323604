import { SvelteSubject } from "@common/store/reactive"
import type { UserListModel, UserViewModel } from "@api"
import { userAuthInfo } from "@common/store/userProfile"
import rest from "@common/services/restService"
import toast from "@common/services/toastService"
import lang from "@common/services/lang"
const url = "userlookup"
const usersSubject = new SvelteSubject<UserListModel[]>([])
export const usersList = usersSubject.asObservable()

userAuthInfo.subscribe(async u => {
    if (u.loggedIn && (u.isAdmin || u.isSeller)) {
        const users = await rest.get<UserListModel[]>(url)
        usersSubject.next(users)
    } else {
        usersSubject.next([])
    }
})

export const getUser = (userid: string) => {
    const users = usersSubject.getValue()
    const user = users.find(u => u.id == userid)
    return user
}

export const addUser = async (user: UserViewModel) => {
    const u = await rest.post<UserViewModel>("user", user)
    toast.success(`${lang("user")} ${lang("saved")}`)
    addusertolist(u)
    return u
}

const addusertolist = (user: UserViewModel) => {
    if (!user)
        return
    const users = usersSubject.getValue()
    users.push(user as UserListModel)
    usersSubject.next(users)
}