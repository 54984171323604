<script lang="ts">
    export let param: UserViewModel
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"
    import Provider from "@common/components/Users/Provider.svelte"
    import rest from "@common/services/restService"
    import geocoder from "@common/services/googleGeocoder"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import UserForm from "./UserForm.svelte"
    import { currentLocation } from "@common/store/state"
    import type { UserViewModel } from "@api"
    import { fetchAllUsersAdmin } from "@common/store/userAdminStore"

    let selectDialogOpen = false

    $ : if (param.location) {
        currentLocation.set(param.location)
    }
    const update = async () => {
        try {
            await tryUpdateLocation()
            if (param.location)
                currentLocation.set(param.location)
            await rest.put("user", param)
            toast.success(`${lang("user")} ${lang("saved")}`)
            fetchAllUsersAdmin()
        } catch (ex)  {
            errorHandler(ex)
        }
    }

    const updateRole = async () => {
        await rest.put("user/changerole", param)
        toast.success("Oppdatert rolle")
        selectDialogOpen = false
    }
    const tryUpdateLocation = async () => {
        let lookupTerm = `${param.address || ""} ${param.postalCode || ""} ${param.place || ""}`
        lookupTerm = lookupTerm.trim()
        if (!lookupTerm)
            return
        
        try {
            const res = await geocoder.geocodeAddress(lookupTerm)
            if (res.location) {
                param.location = res.location
            }
        }
        catch (e) {}
    }

    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }
</script>
<style>
    role-viewer {
        cursor: pointer;
    }
    .roleedit {
        width: 50%;
    }
</style>
<UserForm user={param} />
<ModalDialog bind:open={selectDialogOpen}>
    <div slot="btnContent">
        <role-viewer user={param} on:click={() => selectDialogOpen = true}></role-viewer>
    </div>
    <div slot="dlgContent" class="roleedit">
        <h3>Endre rolle<h3>
        <!-- <role-viewer user={param}></role-viewer> -->
        <mwc-formfield label="Mekaniker">
            <mwc-checkbox checked={param.isSeller} 
                on:change={(e) => param.isSeller = e.target.checked}>
            </mwc-checkbox>
        </mwc-formfield>
        <bd-button on:click={() => updateRole()}>Oppdater</bd-button>
    </div>
</ModalDialog>

<Provider id={param.provider} /><br><br>
<button on:click={update}>{lang('save')}</button>
<br>
