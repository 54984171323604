<script lang="ts">
    import { PartnerType } from "@api"
    import type { PartnerViewModel } from "@api"
    import lang from "@common/services/lang"
    export let partner: PartnerViewModel
    const types: Array<number> = []
    for (let value in PartnerType) {
        if (!isNaN(Number(value)))
            types.push(Number(value))
    }
</script>
<style>
    
</style>

{#each types as type, i}
    <mwc-formfield label="{lang(PartnerType[type])}">
        <mwc-radio 
            name="type" 
            value="{type}"
            checked={type == partner.type}
            on:change={(e) => partner.type = e.target.value}>
        </mwc-radio>
    </mwc-formfield>
{/each}
<br>
<mwc-formfield label="Miljøfyrtårn">
    <mwc-checkbox checked={partner.ecoLighthouse} 
        on:change={(e) => partner.ecoLighthouse = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="Autorisert">
    <mwc-checkbox checked={partner.authorizedWorkshop} 
        on:change={(e) => partner.authorizedWorkshop = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="NBF medlemskap">
    <mwc-checkbox checked={partner.nbfMembership} 
        on:change={(e) => partner.nbfMembership = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="Lånebil tilgjengelig">
    <mwc-checkbox checked={partner.loanCarAvailable} 
        on:change={(e) => partner.loanCarAvailable = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="Garanti på arbeid">
    <mwc-checkbox checked={partner.warranty} 
        on:change={(e) => partner.warranty = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="Videodokumentasjon på arbeid">
    <mwc-checkbox checked={partner.videoDocumentation} 
        on:change={(e) => partner.videoDocumentation = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>
<br>
<mwc-formfield label="Digitalt servicehefte">
    <mwc-checkbox checked={partner.digitalServiceBooklet} 
        on:change={(e) => partner.digitalServiceBooklet = e.target.checked}>
    </mwc-checkbox>
</mwc-formfield>