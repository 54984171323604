import { DocumentState } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('state-viewer')
export class StateViewer extends LitElement {
    
    static styles = css`
        :host {
            display: inline;
        }
    `
    
    @property({attribute: false})
    state: DocumentState = DocumentState.Open

    render() {
        return html`        
            ${this.state == DocumentState.Open ? html`
                <span>Åpen</span>
            ` : html`
                <span>Stengt</span>
            `}
        `
    }
}