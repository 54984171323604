import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { DepartmentViewModel } from "@api"

@customElement('department-viewer')
export class DepartmentViewer extends LitElement {

    static styles = css`
        :host {
            display: inline-block
        }
    `
    @property({attribute: false})
    department: DepartmentViewModel

    @property({attribute: false})
    
    render() {
        if (!this.department)
            return html`<span></span>`
        
        this.setAttribute("title", this.department.headline)
        return html`
            <span>${this.department.name}</span>
        `
    }
}