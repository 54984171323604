import firebase from "firebase/app"
import "firebase/storage"
import { userAuthInfo } from "@common/store/userProfile"
import { StorageFolder } from "@api"

const upload = (rootFolder: StorageFolder, folder: string, filename: string, file: Blob) => {

    const rootFolderString = StorageFolder[rootFolder].toLowerCase()
    const authUser = userAuthInfo.get()
    if (!authUser.loggedIn)
        return

    const fullFolder = `${rootFolderString}/${folder}`
    const uploadTask = uploadActual(fullFolder, filename, file)
    
    uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress}% done`)
    }, (error) => {
        console.error(error)
    }, async () => {
        console.log(`Complete: ${fullFolder}/${filename}`)
    })
    return uploadTask
    
}

const getUrl = async (folder: string, name: string, subfolder: string) => {
    const storageRef = firebase.storage().ref()
    let userRef = storageRef.child(folder)

    let fileRef = userRef.child(name)
    if (subfolder)
        fileRef = userRef.child(`${subfolder}/${name}`)
    const url = await fileRef.getDownloadURL()
    return url
}

const uploadActual = (folder: string, name: string, file: Blob) => {
    const storageRef = firebase.storage().ref()
    const folderRef = storageRef.child(folder)
    const fileRef = folderRef.child(name)
    
    const uploadTask = fileRef.put(file)
    return uploadTask
}

const remove = async (folder: string, name: string) => {
    const storageRef = firebase.storage().ref()
    let userRef = storageRef.child(folder)
    const fileRef = userRef.child(name)

    const res = await fileRef.delete()
    return res
}



export default { upload }