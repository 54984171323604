import Home from "@app/views/Index.svelte"
import SignIn from "@common/views/SignIn.svelte"
import SignUp from "@common/views/SignUp.svelte"
import Account from "@common/views/account/Index.svelte"
import EmailAction from "@common/views/EmailAction.svelte"

import AdminAreas from "@app/views/areas/Index.svelte"
import AdminDepartments from "@app/views/departments/Index.svelte"
import AdminDiagnoses from "@app/views/diagnoses/Index.svelte"
import AdminUsers from "@app/views/users/Index.svelte"
import AdminProducts from "@app/views/products/Index.svelte"
import AdminBlog from "@app/views/blogposts/Index.svelte"
import AdminAssets from "@app/views/assets/Index.svelte"
import Content from "@app/views/content/Index.svelte"
import Partners from "@app/views/partners/Index.svelte"
import Orders from "@app/views/orders/Index.svelte"
import Help from "@app/views/Help.svelte"

import Memberships from "@app/views/Memberships.svelte"

import type { Route } from "@common/models/interfaces"

const routes: Route[] =  [
    {
        path: "/",
        component: Home,
        mustBeLoggedIn: false
    },
    {
        path: "signin",
        component: SignIn,
    },
    {
        path: "signup",
        component: SignUp,
    },
    {
        path: "help",
        component: Help
    },
    {
        path: "account",
        mustBeLoggedIn: true,
        component: Account
    },
    {
        path: "emailAction",
        component: EmailAction,
        title: "epost"
    },
    {
        path: "blog",
        component: AdminBlog,
        mustBeLoggedIn: true
    },
    {
        path: "areas",
        mustBeLoggedIn: true,
        admin: true,
        component: AdminAreas
    },
    {
        path: "departments",
        mustBeLoggedIn: true,
        admin: true,
        component: AdminDepartments
    },
    {
        path: "diagnoses",
        mustBeLoggedIn: true,
        admin: false,
        component: AdminDiagnoses
    },
    {
        path: "users",
        mustBeLoggedIn: true,
        admin: true,
        component: AdminUsers
    },
    {
        path: "products",
        mustBeLoggedIn: true,
        admin: true,
        component: AdminProducts
    },
    {
        path: "assets",
        mustBeLoggedIn: true,
        admin: true,
        component: AdminAssets
    },
    {
        path: "content",
        mustBeLoggedIn: true,
        admin: true,
        component: Content
    },
    {
        path: "partners",
        mustBeLoggedIn: true,
        admin: true,
        component: Partners
    },
    {
        path: "orders",
        mustBeLoggedIn: true,
        component: Orders
    },
    {
        path: "memberships",
        mustBeLoggedIn: true,
        component: Memberships
    }
]
export default routes