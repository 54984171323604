<script lang="ts">
    export let param = ""
    export let action = ""

    import Link from "@common/components/Nav/Link.svelte"
    import { userAuthInfo } from "@common/store/userProfile"

    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import ListAll from "./ListAll.svelte"
    import DiagnoseInfo from "./DiagnoseInfo.svelte"
</script>

<article class="default">
    <header>
        <Link route="/diagnoses">
            <h3>Mine diagnoser</h3>
        </Link>
        <div>&nbsp;&nbsp;&nbsp;</div>
        {#if $userAuthInfo.isAdmin}
            <Link route="/diagnoses/all">
                <h3>Alle diagnoser</h3>
            </Link>
        {/if}
    </header>
    <section class="left">
        <div>
            {#if param == "create"}
                <Create />
            {:else if !param}
                <List />
            {:else if param == "all"}
                <ListAll />
            {:else}
                <DiagnoseInfo param={param} action={action} />
            {/if}
            
        </div>
    </section>

</article>