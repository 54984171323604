<script lang="ts">
    import { gotoPath } from "@common/routing/router"
    import { createProduct } from "@app/store/productStore"
    import lang from "@common/services/lang"
    import ProductForm from "./ProductForm.svelte"
    import type { ProductViewModel } from "@api"
    
    let product: ProductViewModel = {
        routeKey: "",
        name: "",
        description: { html:"", json: ""},
        isDefault: false,
        variants: [{id: null, description: "", "price": 0}]
    }
    
    //let errorMsg;
    let create = async () => {
        //errorMsg = "";
        try {
            await createProduct(product);
            gotoPath("/products");
        } catch (ex)  {
            errorHandler(ex);
        }
    }
    
    let errorHandler = (ex: Error) => {
        //errorMsg = ex.message.substring(0,500);
        console.log(ex.message);
    };
</script>

<ProductForm product={product} />

<button on:click={create}>{lang('create')}</button>