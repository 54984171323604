<script lang="ts">
    import { fetchOrder } from "@common/store/orderStore" 
    import { OrderStatus } from "@api"
    import type { OrderViewModel } from "@api"

    import View from "./OrderView.svelte"
    import Edit from "./OrderEdit.svelte"

    export let orderid = ""
    export let action = ""
    let order: OrderViewModel = {
        created: new Date().toISOString(),
        noteFromBuyer: "",
        orderNumber: "",
        status: OrderStatus.Created,
        lines: []
    }
    let get = async () => {
        order = await fetchOrder(orderid)
    }
    $ : if (orderid) {
        get()
    }
</script>
<style>
    div {
        background: var(--bd-background-secondary);
    }
</style>
<div>
    {#if action == "edit"}
        <Edit order={order} />
    {:else}
        <View order={order} />
    {/if}
</div>
