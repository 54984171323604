<script lang="ts">
    import boot from "./boot"
    import { onMount } from "svelte"
	import "@app/routing"
	import { curRoutePath } from "@common/routing/router"
    import { openMainMenu } from "@common/store"
	import { appInitialized } from "@common/store"
	import config from "@common/config"
	
	import Toast from "@common/components/Toast.svelte"
	
    import "@app/services/pagestate"
    import "@common/services/userauth"
    import "@common/services/userprofile"
	import { userAuthInfo } from "@common/store/userProfile"
    import Container from "@app/routing/Container.svelte"
    import Link from "@common/components/Nav/Link.svelte"
	import FooterNav from "@app/FooterNav.svelte"
	import SideMenu from "@common/components/Nav/SideMenu.svelte"
	import MainMenu from "@app/MainMenu.svelte"

	let mainEl: HTMLElement
    onMount(() => {
        boot.initialize()
        if (!history.state) {
            window.history.replaceState({ path: window.location.pathname }, "", window.location.href)
        }
		curRoutePath.subscribe(val => {
			// setTimeout(() => {
			// 	window.scrollTo({
			// 		top: 0,
			// 		left: 0,
			// 		behavior: "smooth"
			// 	})
			// }, 100)
		})
    })

	const openMenu = () => {
		openMainMenu.set(true)
	}
    
</script>
<style>
	main {
		padding: 0;
		margin: 0 auto;
		display: grid;
		grid-template-rows: auto 1fr auto;
		box-sizing: border-box;
		min-height: 100%;
		width: 1600px;
	}
	main header {
		background: var(--mdc-theme-background);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	main footer {
		display: flex;
		justify-content: center;
		/* background: var(--mdc-theme-primary); */
		z-index: 500;
	}
	#header-upper {
		color: var(--mdc-theme-text-primary-on-dark);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0.5rem;
		width: 1600px;
		box-sizing: border-box;
	}
	.material-icons.menu {
		font-size: 3rem;
		/* color: var(--mdc-theme-surface); */
		color: var(--mdc-theme-primary);
		cursor: pointer;
	}
	.user {
		color: var(--mdc-theme-primary);
	}
	figure.bildoktoren-logo {
		margin-right: 1rem;
	}
	figure figcaption {
		color: var(--mdc-theme-primary);
        text-align: center;
    }

	@media only screen and (max-width: 640px) {
		main {
			width: 100%;
		}
		/* footer {
			position: sticky;
			bottom: 0;
		} */
	}

	@media only screen and (max-width: 640px) {
		#header-upper {
            width: 100%;
        }
	}
	
	@media only screen and (max-width: 1600px) {
		main {
			width: 100%;
		}
        #header-upper {
			width: 100%;
	    }
    }
</style>
<svelte:head>
    <title>Bildoktoren.Admin</title>
</svelte:head>
<main bind:this={mainEl}>
	<header>
		<section id="header-upper">	
			<figure class="bildoktoren-logo home">
				{#if $appInitialized}
					<Link route="/" langCode="home">
						<img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/logos/bd-logo-white-inside-48-fs8.png" alt="bildoktoren logo" height="48" width="48">
					</Link>
				{/if}
				<figcaption>
					{#if config.system}
						{config.system}
					{/if}
				</figcaption>
			</figure>
			{#if $userAuthInfo.loggedIn}
				<span class="user">{$userAuthInfo.email}</span>
			{/if}
			<span class="material-icons menu" on:click={openMenu}>
				menu
			</span>
		</section>
		
	</header>
	{#if $appInitialized}
		<Container />
	{/if}
	
	<footer>
		<FooterNav />
	</footer>
	<SideMenu>
		{#if $appInitialized}
			<MainMenu />
		{/if}
	</SideMenu>
	<Toast />
</main>
