<script lang="ts">
    import { onMount } from "svelte"
    import { ContentStaticIdentifier } from "@api"
    import type { ContentPostModel } from "@api"
    export let param: string
    let paramEnum: ContentStaticIdentifier
    import { getContentPostModel, updateContent } from "@app/store/contentStore"
    import lang from "@common/services/lang"

    import EditBanner from "./EditBanner.svelte"
    import EditRichText from "./EditRichText.svelte"

    let model: ContentPostModel = { content: { assetContents: [], interval: 0, richText: { html: "", json: ""}}}
    
    onMount(async () => {
        model = await getContentPostModel(param)
        paramEnum = ContentStaticIdentifier[param]
        console.log(paramEnum)
    })
    $ : if (param) {
        paramEnum = ContentStaticIdentifier[param]
        console.log(paramEnum)
    }
</script>
<style>
    
</style>
<h2>{lang(param)}</h2>

{#if paramEnum == ContentStaticIdentifier.HomepageBanner}
    <EditBanner model={model} />
{:else if paramEnum == ContentStaticIdentifier.AboutPage 
    || paramEnum == ContentStaticIdentifier.ContactPage 
    || paramEnum == ContentStaticIdentifier.TermsPage 
    || paramEnum == ContentStaticIdentifier.PrivacyPage
    || paramEnum == ContentStaticIdentifier.HowPage
    || paramEnum == ContentStaticIdentifier.MembersPage}
    <EditRichText model={model} />
{:else}
    <p>ingen type spesifisert</p>
{/if}
<button on:click={() => updateContent(model)}>Lagre</button>

