<script lang="ts">
    import { onMount } from "svelte"
    import Form from "./PartnerForm.svelte"
    import Location from "./PartnerLocation.svelte"
    import Properties from "./PartnerProperties.svelte"
    
    import type { PartnerViewModel } from "@api"
    export let partner: PartnerViewModel
    
    import lang from "@common/services/lang"
    
    import { updatePartner } from "@app/store/partnerStore"

    const save = () => {
        updatePartner(partner)
    }

    let tabbarEl
    let activeIndex = 0
    onMount(async () => {
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            activeIndex = e.detail.index
        })
    })
</script>

<mwc-tab-bar bind:this={tabbarEl}>
    <mwc-tab label="{lang('partner')}" icon="info" stacked></mwc-tab>
    <mwc-tab label="{lang('location')}" icon="inventory_2" stacked></mwc-tab>
    <mwc-tab label="Egenskaper" icon="check_circle" stacked></mwc-tab>
</mwc-tab-bar>

{#if activeIndex == 1}
    <Location partner={partner} />
{:else if activeIndex == 2}
    <Properties partner={partner} />
{:else}
    <Form partner={partner} />
{/if}
<br>
<button on:click={save}>{lang('save')}</button>