<script lang="ts">
    import { onMount } from "svelte"
    import type { PartnerViewModel } from "@api"
    export let param = ""
    export let action = ""

    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    let partner: PartnerViewModel
    import { getPartnerAsync } from "@app/store/partnerStore"
    onMount(async () => {
        partner = await getPartnerAsync(param)
    })

    import View from "./PartnerView.svelte"
    import Edit from "./PartnerEdit.svelte"
</script>

{#if partner}
    {#if action == "edit"}
        <Edit partner={partner} />
    {:else}
        <View partner={partner} />
        <ActionLink action="edit" langCode="edit" icon="edit" />
    {/if}
{:else}
    <p>Laster...</p>
{/if}