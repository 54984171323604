import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { UserListModel } from "@api"

@customElement('user-viewer')
export class UserViewer extends LitElement {

    static styles = css`
        :host {
            display: block;
        }
    `
    @property({attribute: false})
    user: UserListModel

    @property({attribute: false})
    showEmail = true
    
    render() {
        if (!this.user)
            return html`<span></span>`
        
        return html`
            <span>${this.user.name}</span>
            ${this.showEmail ? html`
                <small>(${this.user.email})</small>
            `:""}
            
        `
    }
}