import { openMainMenu } from "@common/store"
import { curRoutePath } from "@common/routing/router"

class PageState {
    constructor() {
        this.subscribe()
    }

    subscribe() {
        curRoutePath.subscribe(value => {
            openMainMenu.set(false)
        });
    }
}

export default new PageState()
