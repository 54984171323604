import { SvelteSubject } from "@common/store/reactive"
import { ContentStaticIdentifier } from "@api"
import type { ContentPostModel, ContentViewModel } from "@api"
import toast from "@common/services/toastService"
import rest from "@common/services/restService"
import lang from "@common/services/lang"

const entities: Array<string> = []
    for (let value in ContentStaticIdentifier) {
        if (isNaN(Number(value)))
            entities.push(value)
    }

const contentStoreSubject = new SvelteSubject<Array<string>>(entities)
export const contents = contentStoreSubject.asObservable()

export const getContentPostModel = async (contentId: string | ContentStaticIdentifier) => {
    let vm = await rest.get<ContentPostModel>(`content/getpostmodel/${contentId}`)
    if (!vm)
        vm = { identifier: contentId as ContentStaticIdentifier }
    if (!vm.content)
        vm.content = {}
    if (!vm.content.assetContents)
        vm.content.assetContents = []
    return vm
}

export const getContent = async (contentId: string | ContentStaticIdentifier) => {
    const url = `content/${contentId}`
    let vm = await rest.get<ContentViewModel>(url)
    if (!vm)
        vm.identifier = contentId as ContentStaticIdentifier
    return vm
}


export const updateContent = async (model: ContentPostModel) => {
    const vm = await rest.post<ContentPostModel>("content", model)
    toast.success(`${lang("contents")} ${lang("saved")}`)
    return vm
}