<script lang="ts">
    export let param: string
    import lang from "@common/services/lang"
    import LoadingIndicator from "@common/components/LoadingIndicator.svelte"
    import type { BlogPostViewModel } from "@api"
    import {getPost, updatePost} from "@app/store/blogStore"
    import { gotoPath } from "@common/routing/router"
    import BlogForm from "./BlogForm.svelte"
    
    let post: BlogPostViewModel = { 
        body: {html:"", json:""},
        isDraft: true, onFrontPage: false
    }
    
    let get = async () => {
        post = await getPost(param)
    }
    
    let update = async () => {
        try {
            post = await updatePost(post)
            gotoPath("/blog")
        } catch (ex)  {
            errorHandler(ex)
        }
    }
    
    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }

    
</script>
<style>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
{#await get()}
    <LoadingIndicator />
{:then}
<div class="header">
    <div class="header-elements">
        
    </div>
    <div class="header-elements">
        <small>opprettet <date-viewer date={post.created}></date-viewer></small><br>
        <small>sist endret <date-viewer date={post.lastUpdated}></date-viewer></small>
    </div>
</div>
{:catch error}
    <p style="color: red">{error.message}</p>
{/await}

<BlogForm post={post} />

{#if post.onFrontPage}
    <h3>forhåndsvisning av teksten på forsiden:</h3>
    <html-preview html={post.body.html}></html-preview>
{/if}

<br>
<button on:click={update}>{lang('save')}</button>
