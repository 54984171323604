<script lang="ts">
    import PartnerForm from "./PartnerForm.svelte"
    import lang from "@common/services/lang"
    import { createPartner } from "@app/store/partnerStore"
    import type { PartnerViewModel } from "@api"
    import { gotoPath } from "@common/routing/router"
    let partner: PartnerViewModel = {
        id: null,
        name: "",
        description: {}
    }
    let create = async () => {
        const created = await createPartner(partner)
        gotoPath(`/partners/${created.id}/edit`)
    }
</script>

<PartnerForm partner={partner} />
<br>
<button on:click={create}>{lang('create')}</button>
