import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { BlogPostViewModel } from "@api"

@customElement('blog-table-sortable')
export class BlogTableSortable extends LitElement {
    _posts: BlogPostViewModel[] = []
    
    _sortFunc = new SortFuncs()
    
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        table { border-spacing: 5px; }
        
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }

    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._posts, column);
        this.sortByFn(sortFn);
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._posts);
        let sorted = this._posts.sort(fn);
        this._posts = sorted;
        this.requestUpdate("diagnoses", oldVal);
    }
    
    set posts(val: BlogPostViewModel[]) {
        let oldVal = Array.from(this._posts);
        this._posts = val;
        this.requestUpdate("diagnoses", oldVal)
    }

    get posts(): BlogPostViewModel[] {
        return this._posts;
    }

    render() {
        return html`
        <table>
            <thead>
                <th></th>
                <th class="sortable" @click="${() => this.sort('headline')}">tittel</th>
                <th class="sortable" @click="${() => this.sort('isDraft')}">kladd</th>
                <th class="sortable" @click="${() => this.sort('onFrontPage')}">på fremsiden</th>
                <th class="sortable" @click="${() => this.sort('order')}">rekkefølge</th>
                <th class="sortable" @click="${() => this.sort('lastUpdated')}">Endret</th>
                <th>Url</th>
            </thead>
            <tbody>
            
                ${repeat(this.posts, (d) => d.id, (d, index) => html`
                    <tr>
                        <td>
                            <a @click=${goto} href="/blog/${d.id}/edit">
                                <span class="material-icons" aria-hidden="true" title="${lang('edit')}">edit</span>
                            </a>
                        </td>
                        <td>${d.headline}</td>
                        <td>
                            <boolean-viewer .value=${d.isDraft}></boolean-viewer>
                        </td>
                        <td>
                            <boolean-viewer .value=${d.onFrontPage}></boolean-viewer>
                        </td>
                        <td>
                            ${d.order}
                        </td>
                        <td>
                            <date-viewer .date=${d.lastUpdated}></date-viewer>
                        </td>
                        <td>
                            <small>${d.externalLink}</small>
                        </td>
                    </tr>
                `)}
            
            </tbody>
        </table>
        `;
    }
}