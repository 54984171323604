<script lang="ts">
    import {onMount, onDestroy} from "svelte"
    export let param = ""
    // export let action = ""

    import Edit from "./Edit.svelte"
    import Authorization from "./Authorization.svelte"
    import restService from "@common/services/restService"
    
    import lang from "@common/services/lang"
    import type { UserViewModel } from "@api"
    import { curRoute, setSearchParam } from "@common/routing/router"
    import type { Subscription } from "rxjs"

    let user: UserViewModel = {
        id: "",
        name: "...",
        email: "",
        provider: "password"
    }
    $: if (param) {
        user.id = param
        get()
    }
    let sub: Subscription
    let tabbarEl
    let activeIndex = 0
    onMount(() => {
        sub = curRoute.subscribe(r => {
            if (r.search) {
                if (r.search.has("tab")) {
                    const tabString = r.search.get("tab")
                    activeIndex = parseInt(tabString)
                }
            }
        })
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            // activeIndex = e.detail.index
            setSearchParam("tab", e.detail.index)
        })
    })
    onDestroy(() => sub.unsubscribe())
    let setPageBasedOnUser = (u: UserViewModel) => {
        console.log("user", u)
        if (u.isAdmin)
            return
    }
    
    let get = async () => {
        try {
            user = await restService.get(`user/${param}`)
            setPageBasedOnUser(user)
            
        } catch (err) {
            errorHandler(err)
        }
    }
    let errorHandler = (ex: Error) => {
        console.log(ex.message)
    }
</script>
<h3>
    {lang('edit-user')}
    <small>{user.name}</small>
</h3>

<mwc-tab-bar bind:this={tabbarEl} activeIndex="{activeIndex}">
    <mwc-tab label="{lang('account')}" icon="account_box" stacked></mwc-tab>
    <mwc-tab label="Tilganger" icon="manage_accounts" stacked></mwc-tab>
</mwc-tab-bar>

{#if activeIndex == 1}
    <Authorization param={user} />
{:else}
    <Edit param={user} />
{/if}