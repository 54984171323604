import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { UserViewModel } from "@api"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"

@customElement('role-viewer')
export class RoleViewer extends LitElement {

    static styles = css`
        :host {
            display: flex;
        }
        ${unsafeCSS(materialIconsStyle)}
        span.started {
            color: var(--bildoktoren-blue);
            font-size: 20px;
        }
        span.pending {
            color: var(--success);
        }
    `

    @property({attribute: false})
    user: UserViewModel


    getRoleIcon(u) {
        let title = "Bruker"
        let icon = "account_circle"
        if (u.isAdmin) {
            icon = "account_balance"
            title = "Administrator"
        }
        else if (u.isSeller) {
            icon = "build"
            title = "Mekaniker"
        }
        
        this.setAttribute("title", title)
        
        return html`
            <span class="material-icons" aria-hidden="true" title="${lang(u.role)}">${icon}</span>
        `
    }
    render() {
        return html`
            ${this.getRoleIcon(this.user)}
        `
    }
}