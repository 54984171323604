import firebase from "firebase/app"
import service from "@common/services/fileStorage"
import { yyyymmddhhmmss } from "@common/funcs/helper"
import type { ImageFilePostModel, StorageFolder } from "@api"

export const uploadImageFileToFirebase = async (folder: StorageFolder, 
    subfolder: string, 
    prefixName: string, 
    file: File, 
    onProgress: (progress: number) => void,
    onDone: (model: ImageFilePostModel) => void) => {
    
    let { extension, name } = splitFileNameExtension(file.name)
    const datestamp = yyyymmddhhmmss()
    
    if (prefixName)
        name = `${prefixName}_${datestamp}`
    
    const filename = `original.${extension}`
    const fullSubFolder = `${subfolder}/${name}`

    console.log(`${file.name} as ${folder}/${fullSubFolder}/${name}/${filename} to be uploaded`)
    const uploadTask = service.upload(folder, fullSubFolder, filename, file)
    uploadTask.on("state_changed", (snapshot) => {
        let uploadProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        onProgress(uploadProgress)
        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log("Upload is paused")
                break
            case firebase.storage.TaskState.RUNNING:
                console.log("Upload is running")
                break
        }
    }, (error) => {
        console.log(error)
    }, async () => {
        const fileRef = uploadTask.snapshot.ref
        let url = await fileRef.getDownloadURL()
        const metaData = await fileRef.getMetadata()
        const { size } = file
        const { contentType, bucket, md5Hash } = metaData

        let model: ImageFilePostModel = {
            name,
            extension,
            contentType,
            bucket,
            folder,
            subfolder,
            url,
            md5Hash,
            size
        }
        
        if (onDone)
            onDone(model)
    })
}

const splitFileNameExtension = (fileName: string) => {
    const split = fileName.split(".")
    let name = ""
    let extension = ""
    if (split.length == 1)
        name = split[0]
    else {
        const ext = split.splice(-1)
        extension = ext[0]
        name = split.join(".")
    }
    name = name.toLowerCase()
    extension = extension.toLowerCase()
    return { name, extension }
}