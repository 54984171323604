<script lang="ts">
    import type { ProductViewModel } from "@api"
    export let product: ProductViewModel
    import lang from "@common/services/lang"
    import EditRichTect from "@common/components/RichText/EditRichText.svelte"
    import { uuid } from "@common/funcs/helper"
    
    let addVariant = () => {
        if (product.variants.length === 0)
            product.variants = [{id: uuid(), description: "", "price": 0}]
        else
            product.variants = [...product.variants, {id: uuid(), description: "", "price": 0}]
    }
    let removeVariant = (index: number) => {
        if (index > -1) {
            const newArr = Object.assign([], product.variants)
            newArr.splice(index, 1)
            product.variants = newArr
        }
    }
</script>
<style>
    p {
        margin: 0 0 1rem 0;
    }
    .margin-bottom {
        margin-bottom: 0.3rem;
    }
    input.variant-desc {
        width: 20rem;
    }
    input.variant-price {
        width: 10rem;
    }
    .delete-btn {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }
    .delete-btn:hover {
        color:var(--mdc-theme-error);
    }
</style>
<mwc-textfield label="{lang('name')}" value={product.name} on:change={e => product.name = e.target.value}></mwc-textfield>
<mwc-textfield label="navigering lowercase null space" value={product.routeKey} on:change={e => product.routeKey = e.target.value}></mwc-textfield>
<mwc-textfield label="Rekkefølge" value="{product.order}" on:change={(e) => product.order = e.target.value}></mwc-textfield>
<br>
<EditRichTect model={product.description} />

<h3>Varianter</h3>
<button class="margin-bottom" on:click={(addVariant)}>{lang('add-variant')}</button>
{#each product.variants as variant, i}
    <p>
        <input type="text" class="variant-desc" bind:value={variant.description} placeholder="{lang('description')}" />
        <input type="number" class="variant-price" bind:value={variant.price} placeholder="{lang('price')}" />
        <span class="material-icons delete-btn" title="{lang('remove')}" on:click={() => removeVariant(i)}>delete_forever</span>
    </p>
{/each}

<br>
