<script lang="ts">
    import DepartmentForm from "./DepartmentForm.svelte"
    import lang from "@common/services/lang"
    import { createDepartment } from "@app/store/departmentStore"
    import type { DepartmentViewModel } from "@api"
    let newDep: DepartmentViewModel = {
        id: null,
        name: "",
        mapLookupName: "",
        location: { lat: 0, lng: 0 },
        googlePlaceId: ""
    }
    let create = () => {
        createDepartment(newDep)
    }
</script>

<DepartmentForm department={newDep} />
<button on:click={create}>{lang('create')}</button>
