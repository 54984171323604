<script lang="ts">
    import type { Subscription } from "rxjs"
    import { onMount, onDestroy } from "svelte"
    import type { ImageFileGroupViewModel, ImageFilePostModel } from "@api"
    import {  StorageFolder, ImageSizeType } from "@api"
    import { uploadImageAsset, deleteImageAsset, imageAssetStore } from "@common/store/assetsStore"
    import ImageUploader from "@common/components/Files/ImageUploader.svelte"
    import ModalDialog from "@common/components/Dialogs/ModalDialog.svelte"
    import PopDown from "@common/components/PopDown.svelte"
    import DeleteDialog from "./DeleteDialog.svelte"

    let sub: Subscription
    let assets: ImageFileGroupViewModel[] = []
    onMount(() => {
        sub = imageAssetStore.subscribe(value => {
            assets = value;
        });
    })
    onDestroy(() => sub.unsubscribe())

    let selectedAsset: ImageFileGroupViewModel = {id:"", name:"", notDeletable: true, isCropped: false}
    let deleteDialogState = false
    let uploadDialogState = false
    //let errorMsg
    let create = async (model: ImageFilePostModel) => {
        uploadDialogState = false
        if (!model)
            return
        
        //errorMsg = "";
        try {
            await uploadImageAsset(model)
        } catch (ex)  {
            errorHandler(ex)
        }
    }
    let openDeleteDialog = (asset: ImageFileGroupViewModel) => {
        selectedAsset = asset
        deleteDialogState = true
        console.log("open the dialog please")
    }
    let errorHandler = (ex: Error) => {
        //errorMsg = ex.message.substring(0,500)
        console.log(ex.message)
    };
</script>

<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .image-wrapper {
        flex-basis: 320px;
        flex-grow: 0;
        flex-shrink: 1;
    }
    .thumbnail {
        width: 300px;
    }
    .menu-row {
        display: flex;
        flex-direction: row;
        flex-flow: row-reverse;
    }
    .action:hover {
        background: var(--mdc-theme-primary);
        color: var(--mdc-theme-text-secondary-on-dark);
    }
    .menu-content {
        background: white;
    }
</style>

<div class="new">
    <ModalDialog open="{uploadDialogState}">

        <div slot="btnContent">
            <button on:click={() => uploadDialogState = true}>
                <i class="material-icons mdc-button__icon" aria-hidden="true">library_add</i>
            </button>
        </div>
        <div class="picframe" slot="dlgContent">
            <ImageUploader 
                folder={StorageFolder.Assets} 
                subfolder="pics"
                onClose={(vm) => create(vm)} 
                allowNotCrop={true} />
        </div>
    </ModalDialog>
</div>
<div class="list">
    {#each assets as asset}
    <div class="image-wrapper">
        <div class="menu-row">
            <PopDown>
                <div slot="btnContent">
                    <span class="menu">...</span>
                </div>
                <div slot="dlgContent" class="flex column menu-content">
                    <div>
                        {asset.name}
                    </div>
                    {#if asset.notDeletable}
                        <span>Kan ikke slettes</span>
                    {:else}
                        <div class="action">
                            <i class="material-icons mdc-button__icon" aria-hidden="true" on:click={() => openDeleteDialog(asset)}>delete_forever</i>
                        </div>
                    {/if}
                </div>
            </PopDown>
        </div>
        <image-viewer class="thumbnail" image={asset} wantedsize={ImageSizeType.Thumbnail} alt="main"></image-viewer>
    </div>
    {/each}
</div>
<DeleteDialog openState={deleteDialogState} id={selectedAsset.id} name={selectedAsset.name} remove={deleteImageAsset}/>