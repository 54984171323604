<script lang="ts">
    import { currentLocation } from "@common/store/state"
    import ActionLink from "@common/components/Nav/ActionLink.svelte"
    import lang from "@common/services/lang"
    import type { DepartmentViewModel } from "@api"
    export let deps: DepartmentViewModel[] = []

    currentLocation.set({ lat: 0, lng: 0, title: "" })

    // let highlight = (area: Area) => {
    //     currentLocation.set({ lat: area.location.lat, lng: area.location.lng, title: area.name })
    // }
    
</script>
<style>
    div.new, div.list {
        margin-bottom: 1rem;
    }
    .dep-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list {
        display: flex;
        flex-direction: column;
    }
</style>
<section class="dep-list">
    <div class="new">
        <ActionLink action="create" langCode="create" icon="create_new_folder" /> {lang("create-new")}
    </div>
    <div class="list">
        {#each deps as dep}
            <div>
                <ActionLink param={dep.id} langCode="edit" icon="edit" />
                <span>{dep.name}</span>
                <small> (endret <date-viewer date={dep.lastUpdated}></date-viewer>)</small>
            </div>
        {/each}
    </div>
</section>
