<script lang="ts">
    export let param = ""
    export let action = ""
    import Create from "./Create.svelte"
    import List from "./List.svelte"
    import Edit from "./Edit.svelte"
    import lang from "@common/services/lang"
    import Link from "@common/components/Nav/Link.svelte"
    
</script>
<style>
    #split {
        display: flex;
        flex-direction: row;
    }
    @media only screen and (max-width: 640px) {
        #split {
            display: flex;
            flex-direction: column;
        }
    }
    #split div {
        flex: 1;
    }
</style>
<article class="default">
    <header>
        <Link route="/products">
            <h2>{lang('products')}</h2>
        </Link>
    </header>
    <section id="split">
        <div>
            <List />
        </div>
        <div>
            {#if param == "create"}
                <Create />
            {:else}
                {#if action == "edit"}
                    <Edit param={param} />
                {/if}
            {/if}
            
        </div>
    </section>

</article>
