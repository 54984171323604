import rest from "@common/services/restService"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"
import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import { userAuthInfo } from "@common/store/userProfile"
import type { DepartmentViewModel } from "@api"
const url = "department"

const departmentsSubject = new SvelteSubject<DepartmentViewModel[]>([])
export const departments = clonedObservable(departmentsSubject)

const depByWriteAccessSubject = new SvelteSubject<DepartmentViewModel[]>([])
export const depsByWriteAccess = depByWriteAccessSubject.asObservable()
export const getDepsByWriteAccess = () => {
    return depByWriteAccessSubject.getValue()
}

const fetchAll = async () => {
    const deps = await rest.get<DepartmentViewModel[]>(url)
    departmentsSubject.next(deps)
}

userAuthInfo.subscribe(async user => {
    if (user.loggedIn) {
        try {
            const deps = await rest.get<DepartmentViewModel[]>(`${url}/bywriteaccess`)
            depByWriteAccessSubject.next(deps)
        } catch (e) {
            console.log(e)
        }
    } else {
        depByWriteAccessSubject.next([])
    }
})
fetchAll()

export const createDepartment = async (newDep: DepartmentViewModel) => {
    const dep = await rest.post<DepartmentViewModel>(url, newDep)
    toast.success(`${lang("department")} ${lang("created")}`)
    const currentDeps = departmentsSubject.get()

    departmentsSubject.next([...currentDeps, dep])
}

export const getDepartment = (id: string) => {
    const currentDeps = departmentsSubject.get()
    let dep = currentDeps.find(d => d.id === id)
    return dep
}
export const getDepartmentByRoute = (key: string) => {
    const currentDeps = departmentsSubject.get()
    if (!currentDeps)
        return null
    let dep = currentDeps.find(d => d.routeKey === key)
    return dep
}

export const getDepartmentAsync = async (id: string) => {
    let dep = getDepartment(id)
    if (!dep)
        dep = await rest.get<DepartmentViewModel>(`${url}/${id}`)
    if (!dep.description) {
        dep.description = {}
    }
    return dep
}

export const updateDepartment = async (dep: DepartmentViewModel) => {
    await rest.put(url, dep)
    toast.success(`${lang("department")} ${lang("saved")}`)
    
    const currentDeps = departmentsSubject.get()
    const index = currentDeps.findIndex(d => d.id === dep.id)
    if (index > -1) {
        currentDeps.splice(index, 1, dep)
        departmentsSubject.next(currentDeps)
    }
}
