<script lang="ts">
    export let user: UserViewModel
    import lang from "@common/services/lang"
    import type { UserViewModel } from "@api"

</script>
<style>
    input {
        margin-bottom: 0.3rem;
    }
</style>
<input type="text" bind:value={user.name} placeholder="{lang('name')}" /><br>
<input type="text" bind:value={user.email} placeholder="{lang('email')}" /><br>
<input type="text" bind:value={user.address} placeholder="{lang('address')}" /><br>
<input type="text" bind:value={user.postalCode} placeholder="{lang('postal-code')}" /><br>
<input type="text" bind:value={user.place} placeholder="{lang('place')}" /><br>
