import { SvelteSubject } from "@common/store/reactive"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"
import rest from "@common/services/restService"
import { cloneDeep } from "lodash-es"
import type { ProductViewModel } from "@api"
import SortFuncs from "@common/funcs/sortFuncs"
const sortFun = new SortFuncs()
const url = "product"

const productStoreSubject = new SvelteSubject<ProductViewModel[]>([])
export const productStore = productStoreSubject.asObservable()

const fetchProducts = async () => {
    const products = await rest.get<ProductViewModel[]>(url)
    let sortFn = sortFun.getSortFn(products, "order");
    let sorted = products.sort(sortFn);
    productStoreSubject.next(sorted)
}

fetchProducts()

const getAll = async () => {
    const current = productStoreSubject.get()
    if (current.length === 0) {
        await fetchProducts()
    }
    return productStoreSubject.get()
}

export const getAllClone = async () => {
    const all = await getAll()
    const copy = cloneDeep(all)
    return copy
}

export const getProduct = async (id:string) => {
    let prod = productStoreSubject.getValue().find(p => p.id === id)
    if (!prod) {
        prod = await rest.get<ProductViewModel>(`blog/${id}`)
    }

    var cloneProd = cloneDeep(prod)
    if (!cloneProd.variants)
        cloneProd.variants = []
    return cloneProd
}

export const createProduct = async (product) => {
    let prod = await rest.post<ProductViewModel>(url, product)
    toast.success(`${lang("product")} ${lang("created")}`)
    const currentProducts = productStoreSubject.get()
    productStoreSubject.next([...currentProducts, prod])
    return prod
}

export const updateProduct = async (product) => {
    let prod = await rest.put<ProductViewModel>(url, product)
    const currList = productStoreSubject.getValue()
    const index = currList.findIndex(p => p.id === product.id)
    if (index > -1) {
        const newValue = cloneDeep(currList)
        newValue.splice(index, 1, product)
        productStoreSubject.next(newValue)
    }
    toast.success(`${lang("product")} ${lang("saved")}`)
    return prod
}

export const deleteProduct = async(id: string) => {
    await rest.remove<ProductViewModel>(`${url}/${id}`)
    const newList = productStoreSubject.getValue().filter(p => p.id !== id)
    productStoreSubject.next(newList)
}