import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {getUser} from "@common/store/usersStore"
import {getUserAdmin} from "@common/store/userAdminStore"
@customElement('user-id-viewer')
export class UserIdViewer extends LitElement {

    static styles = css`
        :host {
            display: block;
        }
    `
    @property({attribute: true})
    userid: string

    @property({attribute: false})
    showEmail = true
    
    render() {
        if (!this.userid)
            return html`<span></span>`
        let user = getUser(this.userid)
        if (!user)
            user = getUserAdmin(this.userid)
        return html`<user-viewer .user=${user} .showEmail=${this.showEmail}></user-viewer>`
            
    }
}